import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, CheckboxLabel, Typography } from '../../components/base';
import { Seo } from '../../components/common';
import BoxContainer from '../../components/common/box-container';
import { AppContext } from '../../helpers/hooks/AppContext';
import useBeforeUnloadAndNavigate from '../../helpers/hooks/useBeforeUnloadAndNavigate';
import notify from '../../helpers/toastify-helper';
import { IRegisterForm, IRegisterState } from '../../helpers/types/register.types';
import { registerSchema } from '../../helpers/yup/register.schema';
import { getOtpConfirmation } from '../../services/register.service';
import css from './index.module.scss';
import RegisterContainer from './register-container';

const Register: React.FC = () => {
  const { appDispatch, navigate } = useContext(AppContext);
  const [registerState, setRegisterState] = useState<IRegisterState>({
    isLoading: false,
    isChecked: false,
  });

  const { isChecked, isLoading } = registerState;

  const registerForm = useForm<IRegisterForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(registerSchema),
    defaultValues: {
      mobile: '',
    },
  });
  const {
    handleSubmit,
    formState: { isDirty },
  } = registerForm;

  const handleRegisterSubmit: SubmitHandler<IRegisterForm> = async (data) => {
    const phoneNumber = '+91' + data.mobile;
    const newPhone = { ...data, mobile: phoneNumber };
    localStorage.setItem('mobile', newPhone.mobile);
    setRegisterState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
    const response = await getOtpConfirmation(newPhone);
    if (response.statusCode === 201) {
      notify({ message: 'OTP Sent Successfully!', severity: 'success', dismissible: true });
      navigate('otp-confirmation');
    } else {
      notify({ message: response.message[0], severity: 'error', dismissible: true });
    }
    appDispatch({
      type: 'setRegisterData',
      payload: { phone_number: newPhone.mobile },
    });

    setRegisterState((prevState) => ({
      ...prevState,
      isLoading: !prevState.isLoading,
      isChecked: !prevState.isChecked,
    }));
  };

  useBeforeUnloadAndNavigate();

  return (
    <React.Fragment>
      <Seo title={'Sign In'} />
      <form noValidate onSubmit={handleSubmit(handleRegisterSubmit)}>
        <BoxContainer
          title={<Typography variant="h1">Welcome!</Typography>}
          subheader={
            <React.Fragment>
              <Typography variant="subheading1">Please enter your mobile number to get started.</Typography>
              <section className={css.inputFields}>
                <FormProvider {...registerForm}>
                  <RegisterContainer />
                </FormProvider>
              </section>
            </React.Fragment>
          }
        >
          <div className={css.fieldWrapper}>
            <section className={css.checkbox}>
              <CheckboxLabel
                label={
                  <span className={css.title}>
                    By continuing you agree to our{' '}
                    <a
                      href="https://mitsu.care/terms-and-conditions/"
                      target="_blank"
                      className={css.secondaryTitle}
                      rel="noreferrer"
                    >
                      terms & conditions.
                    </a>
                  </span>
                }
                checked={isChecked}
                value={`${isChecked}`}
                onChange={() =>
                  setRegisterState({
                    ...registerState,
                    isChecked: !registerState.isChecked,
                  })
                }
              />
            </section>
            <section className={css.btnContainer}>
              <Button loading={isLoading} type="submit" disabled={!isDirty || !isChecked}>
                Get Started
              </Button>
            </section>
          </div>
        </BoxContainer>
      </form>
    </React.Fragment>
  );
};

export default Register;
