/* eslint-disable import/named */
import { ReactNode, Ref, forwardRef, useEffect, useMemo, useState } from 'react';
import ReactSelect, {
  ControlProps,
  GroupBase,
  OptionProps,
  PlaceholderProps,
  Props,
  SelectInstance,
} from 'react-select';
import DropdownIndicator from './dropdown-indicator';
import './index.style.scss';
import Option from './option';
import ValueContainer from './value-container';
import { AdaptiveTypography, Modal, Radio } from '../';
import BoxContainer from '../../common/box-container';
import CloseIcon from '../../../assets/icons/CloseIcon';
import Typography from '../typography';
import { Backdrop, Box, IconButton } from '@mui/material';
import Button from '../button';
import { prepareForSlot } from '@mui/base/utils';
import input from '../input/input';

interface SelectProps {
  tagLimit?: number;
  value?: any;
  label?: ReactNode;
  labelClassName?: string;
  inputClassName?: string;
  helperText?: ReactNode;
  error?: boolean;
  rootClassName?: string;
  useMitsuDialog?: boolean;
  modalDialogTitle?: string;

}

const BoxSlot = prepareForSlot(Box);

function RefSelect<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: Props<Option, IsMulti, Group> & SelectProps,
  ref: Ref<SelectInstance<Option, IsMulti, Group>>,
) {
  const { isMulti, value } = props;
  const { label, helperText, error, required, labelClassName, rootClassName, inputClassName, ...otherProps } = props;
  const helperTextClass = error ? 'helper-text-error' : undefined;
  const showAsterisk = required ? ' *' : undefined;
  const selectClasses = useMemo(
    () => ({
      container: () => 'select-container',
      control: (props: ControlProps<Option, IsMulti, Group>) => {
        const { isFocused, isDisabled } = props;
        const disabledClass = isDisabled ? 'select-control-disabled' : null;
        const isFocusedClass = isFocused ? 'select-control-focused' : null;
        return `select-control ${disabledClass} ${isFocusedClass}`;
      },
      indicatorsContainer: () => 'select-indicators-wrapper',
      valueContainer: () => 'select-value-container',
      singleValue: () => 'select-single-value',
      placeholder: (props: PlaceholderProps<Option, IsMulti, Group>) => {
        const { isDisabled } = props;
        const disabledClass = isDisabled ? 'select-control-disabled' : null;
        return `select-placeholder ${disabledClass}`;
      },
      multiValue: () => 'select-multi-value',
      menu: () => 'select-menu',
      menuList: () => 'select-menu-list',
      option: (props: OptionProps<Option, IsMulti, Group>) => {
        const { isSelected } = props;
        const selectedClass = isSelected ? 'select-option-selected' : null;
        return `select-option-item ${selectedClass}`;
      },
      input: ()=> inputClassName??''
    }),
    [],
  );

  const selectComponents = useMemo(
    () => ({
      IndicatorSeparator: null,
      DropdownIndicator: DropdownIndicator,
      Option: Option,
      ValueContainer: ValueContainer,
    }),
    [],
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelected(selectedValue);
  };

  const [selected, setSelected] = useState(props.value);
  const [selectedValue, setSelectedValue] = useState(props.value);

  useEffect(() => {
    if ((props.value || props.value == null) && selected != props.value) {
      setSelected(props.value);
      setSelectedValue(props.value);
    }
  }, [props.value]);

  return (
    <label className={`text-field-root ${rootClassName}`}>
      {label && (
        <span data-value={showAsterisk} className={`text-field-label`}>
          <span className={labelClassName} >
          {label}
          </span>
        </span>
      )}
      {props.useMitsuDialog && (
        <Modal
          open={open}
          onClose={handleClose}
          slots={{
            backdrop: BoxSlot,
          }}
          slotProps={{
            backdrop: {
              className: 'mitsu-select-modal-backdrop',
            },
          }}
        >
          <div className={'mitsu-select-modal-container'}>
            <div className="mitsu-select-modal-heading">
              <Typography variant="h3" className="mitsu-select-modal-title">
                {props.modalDialogTitle}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <hr />
            <div className="mitsu-select-modal-body">
              {props.options?.map((option: any) => {
                if ('label' in option && 'value' in option) {
                  return (
                    <div
                      key={option.value}
                      className="mitsu-select-modal-option-row"
                      onClick={() => setSelected(option)}
                    >
                      <AdaptiveTypography variant="label" mobileVariant="label2">
                        {option.label}
                      </AdaptiveTypography>
                      <div className="mitsu-select-modal-option-spacer" />
                      {
                        // isMultiChoice ?
                        //     <Checkbox  {...register(question.id, { required: true })} value={option} required
                        //         checked={getValues(question.id)!=false && getValues(question.id)?.includes(option)}
                        //     />
                        //     :
                        <Radio checked={selected?.value === option?.value} value={option.value} />
                      }
                    </div>
                  );
                } else {
                  <></>;
                }
              })}
            </div>
            <Button
              disabled = {!selected}
              onClick={() => {
                if (selected != null) {
                  setSelectedValue(selected);
                  setOpen(false);
                  if (props.onChange) {
                    props.onChange(selected, {
                      action: 'select-option',
                      option: selected,
                    });
                  }
                }
              }}
              className="mitsu-select-modal-option-cta"
            >
              Done
            </Button>
          </div>
        </Modal>
      )}
      {props.useMitsuDialog && (
        <ReactSelect
          {...props}
          ref={ref}
          unstyled
          closeMenuOnSelect={!isMulti}
          blurInputOnSelect={!isMulti}
          hideSelectedOptions={false}
          classNames={selectClasses}
          components={selectComponents}
          value={selectedValue ?? (value as any)}
          onMenuOpen={() => {
            setOpen(true);
          }}
        />
      )}
      {!props.useMitsuDialog && (
        <ReactSelect
          {...props}
          ref={ref}
          unstyled
          closeMenuOnSelect={!isMulti}
          blurInputOnSelect={!isMulti}
          hideSelectedOptions={false}
          classNames={selectClasses}
          components={selectComponents}
          value={value as any}
        />
      )}
      {helperText && <span className={`field-helperText ${helperTextClass}`}>{helperText}</span>}
    </label>
  );
}

const Select = forwardRef(RefSelect);

Select.defaultProps = {
  isSearchable: false,
  placeholder: 'Select',
  isClearable: false,
  captureMenuScroll: true,
  menuShouldScrollIntoView: true,
  menuPlacement: 'auto',
  closeMenuOnScroll: false,
  tagLimit: 2,
  defaultValue: { label: null, value: null },
};

export default Select;
export type { SelectProps };
