import { Controller, useFormContext } from 'react-hook-form';
import Images from '../../../assets/images';
import TextField from '../../../components/common/text-field';
import css from './index.module.scss';

const PersonalDetailsContainer = ({ name, email }: any) => {
  const { control } = useFormContext();

  return (
    <section className={css.inputFields}>

      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            required
            disabled={name}
            value={field.value}
            placeholder="Enter Full Name"
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            endIcon={fieldState.invalid && <Images.alertError />}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            required
            disabled={email}
            value={field.value}
            placeholder="Enter Email ID"
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            endIcon={fieldState.invalid && <Images.alertError />}
          />
        )}
      />
    </section>
  );
};

export default PersonalDetailsContainer;
