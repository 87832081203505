import { useEffect, useState } from "react"
import { getPaymentTypes } from "../../../services/selfcheckin.service"

const PriceText = ({priceKey}:{priceKey:string}) => {
    const [price, setprice] = useState('')

    useEffect(
        ()=>{
            const getAndUpdatePrice = async()=> {
                var prices = await getPaymentTypes()
                for(var price of prices.data){
                    if(price.paymentType == priceKey){
                        setprice(price.amount)
                        return
                    }
                }
            }
            getAndUpdatePrice()
        },
        []
    )
    return <>{price}</>
}
export default PriceText;