import { forwardRef, memo, SVGProps } from 'react';
import MitsuLogo from '../../../assets/icons/Mitsu';

const Spinner = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
  const { className, ...otherProps } = props;
  return <MitsuLogo {...otherProps} ref={ref} className={`spinner-wrapper ${className}`} />;
});

Spinner.displayName = 'Spinner';
export default memo(Spinner);
