import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdaptiveTypography } from '../../components/base';
import Button from '../../components/base/button';
import Typography from '../../components/base/typography';
import { CLIENT_ROUTES } from '../../router/routes';
import { getUserEmailId, getUserPhone, markCallScheduleStepCompleted } from '../../services/callSchedule.service';
import style from './scheduleCall.module.scss';

export enum ScheduleType {
  FirstTime,
  Reschedule,
  Cancelled,
}

const CallScheduledPage = ({ scheduleType }: { scheduleType: ScheduleType }) => {
  let title = '',
    description = '';
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const navigate = useNavigate();

  const onNext = () => {
    navigate(`/`, { replace: true });
    if (scheduleType != ScheduleType.Cancelled) {
      navigate(`../${CLIENT_ROUTES.get_to_know}`);
    }
  };

  useEffect(() => {
    getUserEmailId().then((email) => setEmail(email ?? ''));
    getUserPhone().then((phone) => setMobile(phone ?? ''))
    if (scheduleType == ScheduleType.FirstTime || scheduleType == ScheduleType.Reschedule) {
      markCallScheduleStepCompleted();
    }
  }, []);

  switch (scheduleType) {
    case ScheduleType.FirstTime:
      title = 'We look forward to meeting you!';
      description = `Check your ${email} email and WhatsApp message at ${mobile} for the Google Meet link and other details of your upcoming call.`;
      break;
    case ScheduleType.Reschedule:
      title = 'Something came up? We understand';
      description = `Mitsu is made to fit your schedule. Check your ${email} email and WhatsApp message at ${mobile} for the Google Meet link and other details of your upcoming call.`;
      break;
    case ScheduleType.Cancelled:
      title = 'Your call is cancelled';
      description = `We will always be here whenever you need us. Hope to you speak to you soon!`;
      break;
  }

  return (
    <div className={style.callScheduledContainer}>
      <div className={style.scheduledHeaderImage}>
        <div className={style.imageDiv} />
      </div>
      <div className={style.spacer} />
      <div style={{ height: '20px' }} />

      <div className={style.scheduleCallPageCenter}>
        <div className={style.ScheduleCallPage}>
          <div className={`${style.scheduleCallHeadContainer} ${style.scheduledCallHeadContainer}`}>
            <AdaptiveTypography variant="h2" mobileVariant="h3">
              {title}
            </AdaptiveTypography>
            <br />
            <Typography variant="subheading2" className={style.textColorGrey400}>
              {description}
            </Typography>
          </div>
        </div>
      </div>
      <div className={style.spacer} />
      <div style={{ height: '40px' }} />
      <div className={style.spacer} />

      <div className={style.buttonRow}>
        <Button onClick={onNext}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default CallScheduledPage;
