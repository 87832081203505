import { useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import MitsuLogo from '../../assets/icons/Mitsu';
import MitsuLogoMobile from '../../assets/icons/MitsuLogoMobile';
import { Button, Typography } from '../../components/base/index';
import { Seo } from '../../components/common';
import BoxContainer from '../../components/common/box-container/index';
import StepCard from '../../components/common/steps-card/index';
import { OnBoardingData } from '../../helpers/constant';
import { AppContext } from '../../helpers/hooks/AppContext';
import notify from '../../helpers/toastify-helper';
import { getUserDetails } from '../../services/selfcheckin.service';
import { getUserSteps, postCompletedStep } from '../../services/users.service';
import css from './index.module.scss';
import { hideIntroVideo } from '../../helpers/abTest';
import { useSearchParam } from 'react-use';

const OnBoardingDashboard: React.FC = () => {
  const [step, setStep] = React.useState<number>(0);
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const { appDispatch, mixpanel } = useContext(AppContext);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  const query = useSearchParam('hideIntroVideo');

  const hideIntro = query ?? hideIntroVideo();

  const shouldHideIntro = hideIntro == 'B'



  const userSteps = async () => {
    const response: any = await getUserSteps(userId as string);
    if (response?.data) {
      const { data } = response;
      appDispatch({
        type: 'setStepsData',
        payload: {
          stepsData: {
            stepsCompleted: data.stepsCompleted,
          },
        },
      });
      setStep(data.stepsCompleted);
      if( shouldHideIntro &&  data.stepsCompleted == 0){
        const completedStep = { stepsCompleted: 1 };
        const response: any = await postCompletedStep(userId, completedStep);
        userSteps()
      }
    } else if (response.statusCode === 401) {
      notify({
        title: 'Service unavailable',
        message: response.message,
        severity: 'warning',
        dismissible: false,
        showIcon: false,
      });
    } else {
      notify({ message: 'Unable to get data', severity: 'error' });
    }
  };

  useEffect(() => {
    userSteps();
    setEmailVerificationStatus();

    try {
      mixpanel.people.set('hide_intro_abtest', hideIntro)    

    } catch (error) {
      //ignore      
    }
  }, []);

  const setEmailVerificationStatus = async () => {
    const details = await getUserDetails();
    if (details.isEmailVerified) {
      localStorage.setItem('emailVerified', 'true');
    }
  };

  const goToNextStep = () => {
    navigate(`${OnBoardingData.at(step)?.link}`);
  };

  return (
    <React.Fragment>
      <Seo title={'Dashboard'} />
      <BoxContainer
        className={css.container}
        title={
          <Typography variant="h1" className={css.mobileLeft}>
            Welcome to {isMobileView ? <MitsuLogoMobile /> : <MitsuLogo />}
          </Typography>
        }
        subheader={
          <Typography variant="h5" className={css.mobileLeft}>
            You&apos;re on your way to better mental health.
          </Typography>
        }
      >
        <div className={css.dashboardContainer}>
          <div className={css.cardContainer}>
            {OnBoardingData.map((item: any, index: number) => {
              const stepNum = shouldHideIntro? index: index + 1;
              return (
                <StepCard
                  key={index}
                  variant={index <= step ? 'enabled' : 'disabled'}
                  stepDescription={item.description}
                  status={index < step}
                  stepNumber={stepNum}
                  onButtonClick={() =>
                    index <= step && navigate(index < step ? item.reviewLink ?? item.link : item.link)
                  }
                />
              );
            }).filter((item,i)=> shouldHideIntro? i>0: true)}
          </div>
          <div className={css.nextStepButton}>
            <Button onClick={goToNextStep} className={css.button}>
              Go to next step
            </Button>
          </div>
        </div>
      </BoxContainer>
      <Outlet />
    </React.Fragment>
  );
};

export default OnBoardingDashboard;
