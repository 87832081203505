import React from 'react';
import { SVGProps } from '../../helpers/types';

const MitsuLogoMobile: React.FC<SVGProps> = () => {
  return (
    <svg width="72" height="25" viewBox="0 0 148 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3663_2352)">
        <path
          d="M70.6152 46.9979C73.264 44.3052 76.1256 41.8645 79.1734 39.7053V51.4475C79.1734 52.7133 78.7531 53.7986 77.9119 54.6919C77.0926 55.5676 76.0876 55.9994 74.8975 55.9994C73.7234 55.9994 72.7184 55.5676 71.8773 54.6919C71.0361 53.7986 70.6158 52.7133 70.6158 51.4475V46.9979H70.6152Z"
          fill="#FEE600"
        />
        <path
          d="M114.27 46.2548C114.27 49.3672 113.008 51.832 110.479 53.649C108.284 55.2197 105.641 56 102.549 56C99.955 56 97.5517 55.4539 95.3565 54.3567C92.9918 53.1635 91.2985 51.5321 90.2665 49.4689C89.4035 47.7239 89.8462 46.2548 91.5992 45.0609C93.3799 43.8677 95.1927 44.0413 97.0388 45.583C99.3548 47.5263 101.075 48.4853 102.2 48.4619C104.494 48.4076 105.641 47.6765 105.641 46.2548C105.641 45.2592 104.832 44.3898 103.216 43.6461C102.653 43.3702 101.823 43.0343 100.725 42.6385C99.2283 42.1107 98.2779 41.7748 97.8852 41.6252C93.101 39.7602 90.5287 37.0676 90.1682 33.541C97.1481 30.5547 104.739 28.9114 112.68 28.9114C112.801 28.9114 112.926 28.9114 113.046 28.9171C113.248 29.5168 113.237 30.0926 113.008 30.6501C112.68 31.4714 111.981 32.1072 110.905 32.551C109.873 33.0005 108.808 33.1628 107.71 33.0365C106.541 32.8926 105.673 32.4544 105.11 31.7353C104.127 30.6621 102.816 30.2902 101.183 30.6141C99.5663 30.914 98.758 31.5977 98.758 32.6653C98.758 33.7386 99.5663 34.5845 101.183 35.2083C101.718 35.4299 102.538 35.6938 103.635 35.988C105.017 36.3655 105.978 36.6358 106.513 36.8094C111.686 38.5064 114.27 41.6549 114.27 46.2548Z"
          fill="#FEE600"
        />
        <path
          d="M148 41.0493V51.4127C148 52.6779 147.579 53.7638 146.738 54.6571C145.892 55.5328 144.887 55.9646 143.718 55.9646C142.642 55.9646 141.703 55.6047 140.911 54.885C140.114 54.1596 139.633 53.2537 139.469 52.1564C137.716 54.0757 135.624 55.2689 133.188 55.7424C130.824 56.2399 128.535 55.9646 126.313 54.921C123.991 53.8238 122.145 52.0604 120.764 49.6197C119.245 46.9271 118.486 43.6946 118.486 39.9104V29.2112C121.403 29.5048 124.265 30.0269 127.05 30.758V39.9098C127.05 42.1226 127.688 43.8796 128.977 45.1751C130.124 46.369 131.539 46.9681 133.226 46.9681C134.909 46.9681 136.323 46.3803 137.47 45.2111C138.781 43.8916 139.436 42.1226 139.436 39.9098V35.5681C142.429 37.1452 145.296 38.9805 148 41.0493Z"
          fill="#FEE600"
        />
        <path
          d="M41.5172 29.1208C40.1408 26.6081 38.3004 24.8991 36.0121 24.0051C33.833 23.1591 31.6597 23.2078 29.4806 24.1548C27.236 25.1744 25.4445 26.9913 24.1117 29.6063C22.3587 26.0917 19.8789 24.0771 16.6736 23.5556C13.5384 23.0335 10.7861 24.0771 8.42649 26.6921C8.21376 25.8227 7.7216 25.0607 6.95231 24.4136C6.1767 23.7419 5.29185 23.406 4.28166 23.406C3.09094 23.406 2.0865 23.8555 1.26719 24.7495C0.42604 25.6491 0 26.6561 0 27.778V51.448C0 52.7132 0.42604 53.7991 1.26719 54.6924C2.0865 55.5681 3.09094 55.9999 4.28166 55.9999C5.45572 55.9999 6.46073 55.5681 7.30189 54.6924C8.14304 53.7991 8.5639 52.7138 8.5639 51.448V39.4242C8.59093 36.0895 9.53615 33.9247 11.4093 32.929C11.9446 32.6292 12.5069 32.4795 13.0916 32.4795C13.7034 32.4795 14.2766 32.6292 14.8119 32.929C16.7069 33.9247 17.6573 36.1135 17.6573 39.4962V51.448C17.6573 52.7132 18.0667 53.7991 18.886 54.6924C19.7272 55.5681 20.7316 55.9999 21.9005 55.9999C23.0964 55.9999 24.1123 55.5681 24.9535 54.6924C25.7728 53.7991 26.1821 52.7138 26.1821 51.448V39.4956C26.1821 36.1129 27.1325 33.9247 29.0276 32.9284C29.5629 32.6285 30.1361 32.4789 30.7484 32.4789C31.3326 32.4789 31.8949 32.6285 32.4307 32.9284C34.3258 33.924 35.2761 36.1129 35.2761 39.4956V51.4473C35.2761 52.7125 35.6964 53.7984 36.5376 54.6918C37.3787 55.5674 38.3832 55.9993 39.5578 55.9993C40.7485 55.9993 41.753 55.5674 42.5723 54.6918C43.4134 53.7984 43.8395 52.7132 43.8395 51.4473V39.4956C43.8383 35.3938 43.0627 31.9341 41.5172 29.1208Z"
          fill="#3FC6EE"
        />
        <path
          d="M57.4542 24.7912C56.613 23.8915 55.6028 23.442 54.434 23.442C53.2432 23.442 52.233 23.8915 51.4195 24.7912C50.5732 25.6845 50.1523 26.7527 50.1523 27.9996V51.448C50.1523 52.7132 50.5726 53.7991 51.4195 54.6924C52.233 55.5681 53.2432 55.9999 54.434 55.9999C55.6028 55.9999 56.613 55.5681 57.4542 54.6924C58.2953 53.7991 58.7156 52.7138 58.7156 51.448V27.9996C58.7156 26.7521 58.2953 25.6845 57.4542 24.7912ZM57.9946 6.68007C57.0281 5.63647 55.8374 5.11499 54.4334 5.11499C53.0518 5.11499 51.872 5.63647 50.8888 6.68007C49.9056 7.72366 49.4141 8.98318 49.4141 10.4523C49.4141 11.922 49.9056 13.1696 50.8888 14.2068C51.872 15.2384 53.0512 15.7542 54.4334 15.7542C55.8374 15.7542 57.0276 15.2384 57.9946 14.2068C58.9669 13.1696 59.4527 11.922 59.4527 10.4523C59.4527 8.98318 58.9669 7.72366 57.9946 6.68007Z"
          fill="#3FC6EE"
        />
        <path
          d="M85.6346 24.7912C84.7935 23.8916 83.7724 23.4421 82.5816 23.4421H79.1739V4.55129C79.1739 3.2861 78.753 2.2122 77.9119 1.34285C77.0926 0.443197 76.0881 0 74.8974 0C73.7234 0 72.7184 0.443197 71.8772 1.34285C71.036 2.21283 70.6158 3.2861 70.6158 4.55129V23.4414H67.2074C66.0167 23.4414 65.0065 23.891 64.193 24.7906C63.3466 25.6839 62.9258 26.7522 62.9258 27.999C62.9258 29.2706 63.3461 30.3381 64.193 31.2069C65.0065 32.1065 66.0167 32.5503 67.2074 32.5503H70.6158V46.9972C73.2646 44.3045 76.1261 41.8644 79.1739 39.7052V32.551H82.5816C83.7724 32.551 84.7935 32.1071 85.6346 31.2075C86.4539 30.3381 86.8633 29.2706 86.8633 27.9997C86.8633 26.7522 86.4534 25.6846 85.6346 24.7912Z"
          fill="#3FC6EE"
        />
        <path
          d="M112.374 27.6998C111.347 26.3323 110.026 25.2773 108.409 24.5273C106.869 23.8316 104.854 23.4717 102.374 23.4414C98.5344 23.4414 95.6016 24.2274 93.5645 25.7988C91.2711 27.5621 90.124 29.8526 90.124 32.6652C90.124 32.9651 90.1401 33.253 90.1677 33.5402C97.1476 30.554 104.739 28.9107 112.68 28.9107C112.8 28.9107 112.926 28.9107 113.046 28.9163C112.915 28.5211 112.691 28.119 112.374 27.6998Z"
          fill="#3FC6EE"
        />
        <path
          d="M146.738 24.7495C145.892 23.8555 144.887 23.406 143.718 23.406C142.522 23.406 141.517 23.8555 140.698 24.7495C139.857 25.6491 139.436 26.7167 139.436 27.9636V35.568C142.43 37.1451 145.297 38.9804 148 41.0493V27.9636C148 26.7161 147.579 25.6485 146.738 24.7495ZM125.782 24.7495C124.941 23.8795 123.936 23.442 122.768 23.442C121.572 23.442 120.567 23.8795 119.748 24.7495C118.907 25.6491 118.486 26.7281 118.486 27.9996V29.2105C121.402 29.5047 124.265 30.0262 127.05 30.7579V27.9996C127.05 26.7281 126.624 25.6485 125.782 24.7495Z"
          fill="#3FC6EE"
        />
      </g>
      <defs>
        <clipPath id="clip0_3663_2352">
          <rect width="148" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MitsuLogoMobile;
