import React from 'react';
import { SVGProps } from '../../helpers/types';

const PlusIcon: React.FC<SVGProps> = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.07357 12.9206H16.9277C17.1491 12.9206 17.3346 12.8453 17.4844 12.6947C17.6341 12.544 17.709 12.3574 17.709 12.1348C17.709 11.9121 17.6341 11.727 17.4844 11.5794C17.3346 11.4319 17.1491 11.3581 16.9277 11.3581H8.07357C7.85221 11.3581 7.66667 11.4334 7.51693 11.584C7.36719 11.7346 7.29232 11.9212 7.29232 12.1439C7.29232 12.3665 7.36719 12.5516 7.51693 12.6992C7.66667 12.8468 7.85221 12.9206 8.07357 12.9206ZM12.5076 22.6341C11.0712 22.6341 9.7214 22.3607 8.4581 21.8138C7.1948 21.2669 6.09006 20.5204 5.14388 19.5742C4.1977 18.628 3.45117 17.5226 2.9043 16.258C2.35742 14.9935 2.08398 13.6423 2.08398 12.2044C2.08398 10.7666 2.35742 9.41539 2.9043 8.15081C3.45117 6.88621 4.1977 5.78516 5.14388 4.84766C6.09006 3.91016 7.19545 3.16797 8.46005 2.62109C9.72463 2.07422 11.0758 1.80078 12.5137 1.80078C13.9515 1.80078 15.3027 2.07422 16.5673 2.62109C17.8319 3.16797 18.9329 3.91016 19.8704 4.84766C20.8079 5.78516 21.5501 6.88759 22.097 8.15495C22.6439 9.42231 22.9173 10.7742 22.9173 12.2105C22.9173 13.6469 22.6439 14.9967 22.097 16.26C21.5501 17.5233 20.8079 18.6264 19.8704 19.5694C18.9329 20.5124 17.8305 21.2589 16.5631 21.809C15.2958 22.3591 13.9439 22.6341 12.5076 22.6341ZM12.5137 21.0716C14.9703 21.0716 17.0579 20.2079 18.7767 18.4805C20.4954 16.753 21.3548 14.661 21.3548 12.2044C21.3548 9.74783 20.4971 7.66015 18.7816 5.94141C17.0661 4.22266 14.9724 3.36328 12.5006 3.36328C10.0527 3.36328 7.96506 4.22102 6.23763 5.93651C4.5102 7.65201 3.64648 9.74566 3.64648 12.2174C3.64648 14.6654 4.5102 16.753 6.23763 18.4805C7.96506 20.2079 10.0571 21.0716 12.5137 21.0716Z" fill="#3A3939" />
        </svg>

    );
};

export default PlusIcon;
