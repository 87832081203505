import { useMediaQuery, useTheme } from '@mui/material';
import Image1 from '../../assets/images/412shots_so 4.png';
import Image2 from '../../assets/images/502shots_so 1.png';
import { AdaptiveTypography } from '../../components/base';
import Button from '../../components/base/button';
import Typography from '../../components/base/typography';
import { Seo } from '../../components/common';
import css from './index.module.scss';

const EnrollInProgram = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  // console.log(isMobileView)

  const handleClick = () => {
    window.open('https://mitsu.app.link/WGuSNn9b8Ab');
  };

  return (
    <>
      <div className={css.container}>
        <Seo title="Download the App now" />
        <section className={css.layout}>
          <div className={css.titleWrapper}>
            <Typography variant="h1">
              <span className={!isMobileView ? css.size40 : ''}>Only one step away from lifelong mental well-being</span>
            </Typography>
            <div style={{ height: '8px' }} />
            <AdaptiveTypography variant="subheading1" mobileVariant="inherit">
              Download the <span className={css.fontStyle}>mitsu.care</span> app to complete enrolment and get started.
            </AdaptiveTypography>
          </div>
          {isMobileView && (
            <section className={css.btnContainer}>
              <Button variant="contained-nopad" className={css.downloadButton} onClick={handleClick}>
                Download the app now
              </Button>
            </section>
          )}
          <div className={css.backgroundCircle}></div>
          <div className={css.imgWrapper}>
            <section className={css.firstImage}>
              <img width={386} height={522} src={Image2} alt="rep1" />
            </section>
            <section className={css.secondImage}>
              <img width={274} height={445} src={Image1} alt="rep1" />
            </section>
            <div></div>
          </div>
        </section>
        {!isMobileView && (
          <section className={css.btnContainer}>
            <Button variant="contained" onClick={handleClick}>
              Download the app now
            </Button>
          </section>
        )}
      </div>
    </>
  );
};

export default EnrollInProgram;
