import { Accordion as MuiAccordion, AccordionDetails, AccordionProps, AccordionSummary, styled, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntersection, useSearchParam, useTween } from 'react-use';
import { AdaptiveTypography, Button, Typography } from '../../components/base';
import { EXTERNAL_LINKS, MixPanelEvents } from '../../helpers/constant';
import { AppContext } from '../../helpers/hooks/AppContext';
import { CLIENT_ROUTES } from '../../router/routes';
import { getCheckinSummaryResult, getUserDetails, markCheckinStepCompleted } from '../../services/selfcheckin.service';
import style from './checkinQuestions.module.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import QuoteIcon from '../../assets/icons/QuoteIcon';
import "./carouselOverride.css"
import PlusIcon from '../../assets/icons/PlusIcon';
import MinusIcon from '../../assets/icons/MinusIcon';
import ReactPlayer from 'react-player';

import WhatsAppOutlinedImg from '../../assets/icons/WhatsappOutlined';
import notify from '../../helpers/toastify-helper';

import CheckinSummaryCurrentNoText from './checkInSummaryCurrentNoText';
import  { getAnietyText, getDepressionText } from '../../helpers/abTest';
import CheckinSummaryCurrentOldText from './checkInSummaryCurrentOldText';
import CheckinSummaryOld from './checkinSummaryOld';
import CheckinSummaryCurrentNoTextSmall from './checkInSummaryCurrentNoTextSmall';



const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,

  '&::before': {
    display: 'none',
  },
}));

const CheckinSummary = () => {

  let param = useSearchParam('test')


  const theme = useTheme();
  const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));
  const [summary, setSummary] = useState<any>(null);
  const navigate = useNavigate();
  const tween = useTween('outCubic', 700);

  const [pageTime, _] = useState(new Date())
  const [clickedVideo, __] = useState<any>({})

  const onNext = () => {
    navigate(`../../${CLIENT_ROUTES.dashboard}`);
    navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.setup_free_call}/${CLIENT_ROUTES.call_explainer}`);
  };

  const onReview = () => {
    navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.mood_check_in}/${CLIENT_ROUTES.mood_check_in_questionaire}`);
  }
  const { mixpanel } = useContext(AppContext);

  const [expandedQuestion, setExpandedQuestion] = useState<any>(null)
  const [aspectRatio, setAspectRatio] = useState<any>(null);

  const playerRef = useRef<any>(null);

  const testimonials = [
    {
      title: 'Best decision of my life',
      content: 'It was one of the best decision of my life to go with Mitsu.care. It helped me alot during tough phase in my life. My therapist Vidula was blessing for me.She guided me in this throughout journey. Mitsu not only helps us during difficult times also it teaches us skills to upgrade in daily life challenges. Thank u Mitsu.care and Vidula.',
      user: 'Varsha Kulkarni',
      platform: 'Rated ⭐⭐⭐⭐⭐ on Google Play Store'
    },
    {
      title: 'Wanted to feel better … Mitsu delivered',
      content: 'I wanted a way to feel better that was scientific but that I could do myself, and Mitsu delivered. The videos are top notch. And being able to chat with a psychologist to clear my doubts helped me feel a lot more grounded, much more quickly than muddling through alone.',
      user: 'Rohan Jaikishen',
      platform: 'Rated ⭐⭐⭐⭐⭐ on Google Play Store'
    },
    {
      title: 'Empowers you to self-heal',
      content: 'Undoubtedly the one of the best decision I made in life was spending on this app for my wellbeing. It empowers u to self heal with a expert. (In my case indebted to ms. RAKSHA) she has given that soft nudge wen need and always a lot of kind words. And a loads of patience to hear me out. Wen no one was there. .. (mind u it not easy to come out and seek help) . She made me to DO the WORK. .',
      user: 'Lakshmi',
      platform: 'Rated ⭐⭐⭐⭐⭐ on Google Play Store'
    },
    {
      title: 'Lives up to the promise',
      content: 'I found myself in a downward spiral when someone told me to take this up ! Not only has this helped me stop the spiral , but it has got me back to life and may be even more ! Lives up to the promise with an extremely caring team to ensure my well being . The team has gone out of their way to show care and concern Highly recommended for everyone to take this course ❤️',
      user: 'Varun',
      platform: 'Rated ⭐⭐⭐⭐⭐ on Google Play Store'
    },
    {
      title: 'Helping me become a better version of me',
      content: 'Mitsu care is helping me become a better version of me. Clearly a lot of care and thought has gone into building the app. The lessons don\'t feel rushed. I can take them on the go. They know the difference between reading about and putting the skills to use they\'ve given time to soak it all in. My favorite part of the journey though has been the constant check-ins with their therapist. Yes I signed up to help myself, but it really helped to have a trained and empathetic counsellor nudge me along and explain a lesson to me in MY context, when I got stuck. Go on, give mitsu care a shot and download it - one of the best gifts you can give yourself.',
      user: 'Srishti',
      platform: 'Rated ⭐⭐⭐⭐⭐ on The App Store'
    },
  ]


  const FAQS = [

    //Check-in score page FAQs
    {
      question: 'What does my score mean?',
      answer: `Your scores measure symptoms of anxiety and depression, which are good indicators of your overall mental health. Essentially, they reveal which parts of life you may be struggling with and how distressing you find these struggles. 

You can think of this assessment as a thermometer. A higher score, just like a higher body temperature, means you’re likely struggling inside. Watch the video above to learn more about your scores and understand how you can feel better!`,
    },
    {
      question: 'What is the Mood Check-in based on? Is it reliable?',
      answer: `Mitsu’s Mood Check-in is based on the PHQ-9 and the GAD-7, two gold-standard assessments of depression and anxiety symptoms respectively. They are used by clinicians all over the world to gauge mental health.`,
    }, {
      question: 'What is my next step?',
      answer: `Whether you have a high or low score, your next step is to schedule an intake call with one of Mitsu’s psychologists to learn more about your score and how Mitsu can help you feel better.`,
    },
    {
      question: 'Do I still need the call if my scores are low?',
      answer: 'The intake call is a critical step in the Mitsu program. It helps you: \n\n1) Get to know your therapist, who will support you throughout the program.\n2) Understand the program and how it will benefit you.\n\nIt helps your therapist:\n\n1) Personalize the program with the lessons and activities that will best help you.\n2) Understand your specific struggles and goals and how they can best support you once you enroll.',
    },

  ]

  useEffect(() => {
    const getCheckInSummary = async () => {
      try {
        await markCheckinStepCompleted();

        try {
          (window as any).fbq('trackCustom', 'Check_in_completed_event')
        } catch (error) {
          console.log('Cannot send custom pixel')
        }
        mixpanel.track(MixPanelEvents.checkInCompleted, {
          'Week Number': '0',
          isWebFlow: true,
        });
      } catch (error) {
        console.warn(error);
      }
      const summary = await getCheckinSummaryResult();
      setSummary(summary);
    };
    getCheckInSummary();

  }, []);

  const videoUrl = summary ? summary?.find((cat: any) => cat.score >= 10) ? '/assets/video/High_Score/video-pl.m3u8' : '/assets/video/Low_Score/video-pl.m3u8' : null;
  const thumbUrl = summary ? summary?.find((cat: any) => cat.score >= 10) ? '/assets/video/High_Score/thumb.jpg' : '/assets/video/Low_Score/thumb.jpg' : null;
  if (summary) {
    if (summary?.find((cat: any) => cat.score >= 10)) {
      clickedVideo.scoretype = 'high'
    } else {
      clickedVideo.scoretype = 'low'
    }
  }
  useEffect(() => {
    const loadImage = new Image();
    loadImage.src = thumbUrl ?? '';

    loadImage.onload = () => {
      const newAspectRatio = loadImage.width / loadImage.height;
      setAspectRatio(newAspectRatio);
    };
  }, [summary]);

  const [amountSeen, setAmountSeen] = useState(0);
  const seenAmountRef = useRef(0);

  useEffect(() => {
    seenAmountRef.current = amountSeen;
  }, [amountSeen]);

  useEffect(
    () => () => {
      firePageExited()
    },
    [],
  );

  const firePageExited = () => {
    try {
      const watchTime = ~~(seenAmountRef.current / 1000)
      if (watchTime > 0) {
        const videoType = clickedVideo.scoretype == 'high' ? 'High Score' : 'Low Score'
        mixpanel.track(MixPanelEvents.videoPlayed, {
          'Time Elapsed': `${~~(seenAmountRef.current / 1000)} seconds`,
          'Video Type': videoType,
          isWebFlow: true,
        });
      }
    } catch (error) {
      //Ignore
    }
    mixpanel.track(MixPanelEvents.exitedCheckinSummary, {
      'Page Time': (((new Date()) as any) - (pageTime as any)),
      isWebFlow: true,
    }, { transport: 'sendBeacon' }
    );

    document.removeEventListener('visibilitychange', firePageExited)
  }

  useEffect(
    () => {
      document.addEventListener(
        'visibilitychange',
        firePageExited
      )
    }, []
  )



  const onVideoProgress = async (val: number) => {
    setAmountSeen(val)
  }

  const requestCallback = async () => {
    let data = await getUserDetails();

    const formdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("id", data.id);
    formdata.append("email", data.email);
    formdata.append("mobile", data.mobile);


    await fetch("https://mitsu.care/wp-json/custom/v1/notify-callback-request", {
      method: 'POST',
      body: formdata,
      mode: 'no-cors'
    });

    notify({
      message: "Thank you! Our customer support will reach out to you soon.",
      severity: 'success',
      dismissible: true,
    });
  }


  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
      root: null,
      rootMargin: '0px',
      threshold: 1
  });


  return (
    <div className={style.questionsPageContainer}>
      <div className={style.questionsPageCenter}>
        <div className={style.questionsPage}>
          <div className={`${style.summaryHeader} `}>
            <AdaptiveTypography variant="h2" mobileVariant="h3" className={`${style.summaryHeader}  ${isTwoColumn ? '' : style.alignJustify}`}>
              Your Check-in Summary
            </AdaptiveTypography>
            <div className={`${style.summaryGroup} ${isTwoColumn ? style.col_2 : style.col_1}`}>
              {summary &&
                summary?.map((summaryCat: any, index:number) => {
                  const currentScore = ~~(tween * summaryCat.score);
                  return (
                    <div key={summaryCat.category} className={style.summaryResultBox}>

                      <Typography variant="label" className={style.scoreSuggests}>
                        {' '}
                        {summaryCat.scoreRange.length > 1 && summaryCat.scoreRange[1]
                          ? `A score between ${summaryCat.scoreRange[0]} to ${summaryCat.scoreRange[1]} suggests`
                          : `A score of ${summaryCat.scoreRange[0]} or above suggests`}{' '}
                      </Typography>
                      <AdaptiveTypography variant="h3" mobileVariant="h2mid" className={style.summaryCategoryTitle}>
                        {summaryCat.level}
                      </AdaptiveTypography>

                      <div className={style.summaryProgressBarContainerBox}>
                        <AdaptiveTypography className={style.summaryProgressBarLegend}>
                          0
                        </AdaptiveTypography>
                        <div className={style.summaryProgressBarContainer}>
                          <div className={style.summaryProgressBarBottom} />
                          <div
                            className={style.summaryProgressBarTop}
                            style={{
                              width: `${(currentScore * 100) / summaryCat.totalScore}%`,
                              backgroundSize: `${(summaryCat.totalScore / currentScore) * 100}%`
                            }}
                          />
                          <div className={style.summaryProgressBarHighlight} />
                          <div className={style.summaryProgressBarHighlightCircle} />

                        </div>
                        <AdaptiveTypography className={style.summaryProgressBarLegend}>
                          {summaryCat.totalScore}
                        </AdaptiveTypography>


                        <AdaptiveTypography
                          className={`${style.scoreText}`}
                          variant="h3"
                          mobileVariant="h1"
                        >
                          {`${currentScore}/${summaryCat.totalScore}`}
                        </AdaptiveTypography>

                      </div>



                      <div className={style.summaryResultContent}>
                          <AdaptiveTypography
                              variant="subheading3"
                              mobileVariant="subheading1"
                              className={style.summaryCategoryDescription}
                          >
                            {
                              index == 0 ? getDepressionText(summaryCat.score)?.text : getAnietyText(summaryCat.score)?.text                                                  
                            }
                          </AdaptiveTypography>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className={style.spaceStandard}></div>
          <AdaptiveTypography variant="h2" mobileVariant="h3" className={`${style.testimonialHeader}  ${isTwoColumn ? style.alignCenter : style.alignJustify}`}>
            Understanding your score
          </AdaptiveTypography>
          <div ref={playerRef} className={style.videoContainer}>
            <div ref={intersectionRef} className={style.video} style={{ aspectRatio: aspectRatio ?? 1 }}>
              <ReactPlayer
                key={videoUrl ?? ''}
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                url={videoUrl ?? ''}
                width="100%"
                // playIcon={<PlayButton />}
                playing={!(intersection && intersection.intersectionRatio < 1)}
                height="100%"
                controls={true}
                // light={thumbUrl!}
                playsinline={true}
                onClickPreview={
                  (ev) => {
                    mixpanel.track(MixPanelEvents.videoClicked, {
                      isWebFlow: true,
                    });
                    clickedVideo.clicked = true
                  }
                }
                onProgress={(val) => onVideoProgress(val.playedSeconds * 1000)}
              />
            </div>

          </div>
          <div className={style.spaceStandard}></div>
          <div className={style.buttonRow}>
            <Button variant='contained' onClick={onNext}>
              Go to next step
            </Button>
          </div>
              <div style={{ height: '20px' }} ></div>
              <div onClick={onReview} className={style.reviewResponse}>Review your responses</div>
          

          <div style={{ height: '32px' }} ></div>
          <AdaptiveTypography variant="h2" mobileVariant="h3" className={`${style.testimonialHeader}  ${isTwoColumn ? style.alignCenter : style.alignJustify}`}>
            What users say about Mitsu
          </AdaptiveTypography>
          <Carousel
            responsive={
              {

                desktop: {
                  breakpoint: { max: 3000, min: 768 },
                  items: 2
                },

                mobile: {
                  breakpoint: { max: 768, min: 0 },
                  items: 1
                }
              }
            }
            autoPlay
            infinite
            showDots
            customDot={
              <CustomDot />
            }
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
          >
            {
              testimonials.map(
                (testimonial) => <div className={style.testimonialContainer}>
                  <QuoteIcon />
                  <Typography className={style.testimonialTitle}>
                    {testimonial.title}
                  </Typography>
                  <Typography className={style.testimonialContent}>
                    {testimonial.content}
                  </Typography>
                  <Typography className={style.testimonialUser}>
                    {testimonial.user}
                  </Typography>
                  <Typography className={style.testimonialPlatform}>
                    {testimonial.platform}
                  </Typography>
                </div>
              )
            }
          </Carousel>


          <div className={style.spaceStandard}></div>
          <div className={style.buttonRow}>
            <Button variant='contained' onClick={onNext}>
              Go to next step
            </Button>
          </div>
          <div className={style.spaceStandard}></div>
          <div className={style.spaceStandard}></div>

          <div style={{ height: '8px' }} ></div>
          <AdaptiveTypography variant="h2" mobileVariant="h3" className={`${style.testimonialHeader}  ${isTwoColumn ? style.alignCenter : style.alignJustify}`}>
            Frequently Asked Questions
          </AdaptiveTypography>

          <div className={style.faqBox}>
            {
              FAQS.map((faq) =>
                <Accordion expanded={expandedQuestion == faq.question} onChange={() => expandedQuestion == faq.question ? setExpandedQuestion(null) : setExpandedQuestion(faq.question)} style={{ backgroundColor: 'transparent', boxShadow: 'unset' }}>
                  <AccordionSummary
                    style={{ padding: 0 }}
                    expandIcon={(expandedQuestion == faq.question) ? <MinusIcon /> : <PlusIcon />}
                  >
                    <Typography className={style.faqHeading} >{faq.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: 0, paddingBottom: '10px' }}>
                    <Typography className={style.faqContent}>
                      <div dangerouslySetInnerHTML={
                        { __html: faq.answer }}></div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )
            }

          </div>

          <div className={style.spaceStandard}></div>
          <div className={style.buttonRow}>
            <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9987806854?text=Hello,%20I%20am%20interested%20in%20Mitsu%27s%20program.%20How%20can%20I%20learn%20more"
              >
                <Button variant='contained' className={style.contactUs}>
                  <WhatsAppOutlinedImg />

                  Talk with us
                </Button>
            </a>
          </div>
          <div className={style.spaceStandard}></div>

          <div className={style.noteBox}>
            <Typography variant='h4' className={`${style.noteHeader} ${style.alignJustify}`} >Note</Typography>
            <AdaptiveTypography variant='subheading3' mobileVariant='body' className={`${style.alignJustify}`} >
              <span className={style.noteText} >The depression and anxiety symptoms scores calculated here are based on the PHQ-9 and GAD-7 questionnaires from the PRIME-MD. The PHQ-9 simply scores each of the nine DSM-IV criteria based on the mood module on a scale of 0-27. The GAD-7 scores seven common anxiety symptoms on a scale of 0-21. Please note that these questionnaires cannot be used as replacement for clinical assessment, and additional evaluation should be used to confirm diagnosis for any depressive or anxiety related disorders. Read more about these here: <a className={style.external_link} target='_blank' href={EXTERNAL_LINKS.phq_9}>PHQ-9,</a> <a className={style.external_link} target='_blank' href={EXTERNAL_LINKS.gad_7} >GAD-7</a></span>
            </AdaptiveTypography>
          </div>

          <div className={style.spacer} />
          <div style={{ height: '40px' }} />
        </div>
      </div >
    </div >
  );
}


const CustomDot = ({ onClick, ...rest }: any) => {
  const {
    index,
    active,
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <div
      className={`${style.carouselIndicator} ${active ? style.indicatorSelected : ""}`}
      onClick={() => onClick()}
    >
    </div>
  );
};

const CustomLeftArrow = ({ onClick, ...rest }: any) => {
  const {
    onMove,
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <button onClick={onClick} className={style.caouselLeftArrow} ></button>;
};

const CustomRightArrow = ({ onClick, ...rest }: any) => {
  const {
    onMove,
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <button onClick={onClick} className={style.caouselRightArrow} ></button>;
};


export default CheckinSummary;
