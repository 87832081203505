import React, { createContext, useReducer } from 'react';
import { appReducer } from './appReducer';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { getCurrentUserId } from '../../services/selfcheckin.service';
import { MixPanelEvents } from '../constant';

interface IAppContextProps {
  children: React.ReactNode;
}

export const AppContext = createContext<any>({});

const AppContextProvider: React.FC<IAppContextProps> = (props) => {
  const initialState = {
    registerData: {
      phone_number: '',
    },
    userData: {},
    stepsData: {},
  };
  const navigate = useNavigate();

  mixpanel.init(`${import.meta.env.REACT_APP_MIXPANEL_TOKEN}`, {
    debug: import.meta.env.REACT_APP_MIXPANEL_DEBUG,

    persistence: 'localStorage',
  });

  const regex = /campaign=(\w*)(&|$)/gmi;
  try {
    // console.log(window.location.href);
    let result = regex.exec(window.location.href)
    if (result != null) {
      // console.log(result)
      let campaign = result[1];
      mixpanel.people.set('utm_campaign_custom', campaign)
      mixpanel.people.set('utm_campaign_custom_url', window.location.href)

      localStorage.setItem('utm_campaign_custom', campaign)
      localStorage.setItem('utm_campaign_custom_url', window.location.href)

    }
  } catch (e) {
    // console.error(e)

  }
  getCurrentUserId().then((id) => {
    if (id != null) {
      mixpanel.identify(id);
    }
  });

  const [appState, appDispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ appState, appDispatch, navigate, mixpanel }}>{props.children}</AppContext.Provider>
  );
};

export default AppContextProvider;
