import React from 'react';
import { SVGProps } from '../../helpers/types';

const AlertError: React.FC<SVGProps> = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.33333 9.00001H7.66667V10.3333H6.33333V9.00001ZM6.33333 3.66668H7.66667V7.66668H6.33333V3.66668ZM7 0.333344C3.31333 0.333344 0.333333 3.33334 0.333333 7.00001C0.333333 8.76812 1.03571 10.4638 2.28595 11.7141C2.90501 12.3331 3.63994 12.8242 4.44878 13.1592C5.25761 13.4942 6.12452 13.6667 7 13.6667C8.76811 13.6667 10.4638 12.9643 11.714 11.7141C12.9643 10.4638 13.6667 8.76812 13.6667 7.00001C13.6667 6.12453 13.4942 5.25762 13.1592 4.44879C12.8242 3.63995 12.3331 2.90502 11.714 2.28596C11.095 1.66691 10.3601 1.17584 9.55122 0.840813C8.74239 0.505782 7.87548 0.333344 7 0.333344ZM7 12.3333C5.58551 12.3333 4.22896 11.7714 3.22876 10.7712C2.22857 9.77105 1.66667 8.4145 1.66667 7.00001C1.66667 5.58552 2.22857 4.22897 3.22876 3.22877C4.22896 2.22858 5.58551 1.66668 7 1.66668C8.41449 1.66668 9.77104 2.22858 10.7712 3.22877C11.7714 4.22897 12.3333 5.58552 12.3333 7.00001C12.3333 8.4145 11.7714 9.77105 10.7712 10.7712C9.77104 11.7714 8.41449 12.3333 7 12.3333Z"
        fill="#F04438"
      />
    </svg>
  );
};

export default AlertError;
