import { AdaptiveTypography, Button } from "../../components/base";
import style from './callexplainerscreen.module.scss'

import ResultIcon from '../../assets/images/result.png';
import ControlIcon from '../../assets/images/control.png';
import ComputerBookIcon from '../../assets/images/computerbook.png';
import NotebookIcon from '../../assets/images/Notebook.png';
import HandshakeIcon from '../../assets/images/Handshake.png';
import ReactPlayer from "react-player";
import { useContext, useEffect, useRef, useState } from "react";
import TeenImage from '../../assets/images/high-angle-teenage-girl-with-headphones-online-school 1.png';
import MitsuLogo from "../../assets/icons/Mitsu";
import MitsuLogoMobile from "../../assets/icons/MitsuLogoMobile";
import { Accordion, AccordionDetails, AccordionSummary, Typography, useMediaQuery, useTheme } from "@mui/material";
import MinusIcon from "../../assets/icons/MinusIcon";
import PlusIcon from "../../assets/icons/PlusIcon";
import WhatsAppOutlinedImg from "../../assets/icons/WhatsappOutlined";
import { useNavigate } from "react-router-dom";
import { CLIENT_ROUTES } from "../../router/routes";
import { AppContext } from '../../helpers/hooks/AppContext';
import { MixPanelEvents } from "../../helpers/constant";
import { useIntersection } from "react-use";
import { getUserDetails } from "../../services/selfcheckin.service";
import PriceText from "../../components/common/price-text";


const CallExplainerScreen = () => {

    let points = [
        {
            icon: ResultIcon,
            description: 'Discuss your check-in results & ask questions'
        },
        {
            icon: ControlIcon,
            description: 'Identify mental health goals to work on'
        },
        {
            icon: ComputerBookIcon,
            description: 'Understand how the program can help achieve your goals'
        },
        {
            icon: NotebookIcon,
            description: 'Get the program personalised to your needs'
        },
        {
            icon: HandshakeIcon,
            description: 'Get to know the therapist who will guide you through the program'
        }
    ]

    const { mixpanel } = useContext(AppContext);


    const videoUrl = '/assets/video/Summary_Explainer/video-pl.m3u8'
    const thumbUrl = '/assets/video/Summary_Explainer/thumb.png'
    const [aspectRatio, setAspectRatio] = useState<any>(null);

    const [amountSeen, setAmountSeen] = useState(0);
    const seenAmountRef = useRef(0);

    const firePageExited = () => {
        try {
            const watchTime = ~~(seenAmountRef.current / 1000)
            if (watchTime > 0) {
                mixpanel.track(MixPanelEvents.therapistVideoPlayed, {
                    'Time Elapsed': `${~~(seenAmountRef.current / 1000)} seconds`,
                    isWebFlow: true,
                }, { transport: 'sendBeacon' });
            }
        } catch (error) {
            //Ignore
        }

        document.removeEventListener('visibilitychange', firePageExited)
    }

    useEffect(
        () => () => {
            firePageExited()
        },
        [],
    );

    useEffect(
        () => {
            document.addEventListener(
                'visibilitychange',
                firePageExited
            )
        }, []
    )
    const onVideoProgress = async (val: number) => {
        setAmountSeen(val)
    }

    useEffect(() => {
        try {
            mixpanel.track(MixPanelEvents.explainerPageView, {
                isWebFlow: true,
            });
        } catch (error) {
            //
        }
        const loadImage = new Image();
        loadImage.src = thumbUrl ?? '';

        loadImage.onload = () => {
            const newAspectRatio = loadImage.width / loadImage.height;
            setAspectRatio(newAspectRatio);
        };
    }, []);
    const theme = useTheme();


    const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));


    const FAQS = [



        //Call scheduled page FAQs
        {
            question: 'Why do I need to attend this call?',
            answer: `The intake call is a critical step in the Mitsu program. It helps you: 
1) Understand your score better
2) Set goals for your mental health
3) Get to know the therapist who will guide you in your journey to better mental health
4) Learn more about how Mitsu’s program works

It helps your therapist:
1) Personalise the program with the lessons and activities that will best help you.
2) Understand your specific struggles and goals and how they can best support you once you enrol.`,
        },
        {
            question: 'Who will be my therapist for the call?',
            answer: `Your therapist will be an experienced psychologist, licensed by the Rehabilitation Council of India (RCI), from Mitsu’s in-house clinical team. Our psychologists have graduated from some of India’s best universities and are trained to assist you with a range of emotional and mental health concerns, no matter how mild or challenging they may seem.

The therapist you meet in the call will be the same one who guides you through your Mitsu journey.

Of course, a positive and trusting patient-therapist relationship is critical; if, for some reason, you are not feeling effectively supported by your therapist during your Mitsu journey, you can always request a change of therapist by emailing hello@mitsu.care, subject line: Requesting Change of Therapist.`,
        },
        {
            question: 'Is it common to feel hesitant about seeking help or taking this call?',
            answer: `Yes, it’s very common. Most people, to different degrees, find it difficult to come to terms with or even specify their life struggles, and speaking to a therapist for the first time often feels uncomfortable, vulnerable, and for some, maybe hopeless. 

But reaching out for professional help, from Mitsu or otherwise, is the first step in feeling better. Taking action toward feeling better is a sign of strength and capability.

If talking with your Mitsu psychologist on video call feels uncomfortable, you can always keep the video off and use audio only.`
        },
        {
            question: 'How long will the call take?',
            answer: `The call will last about 30 minutes.`
        },
        {
            question: 'Will I have to answer questions about my life problems and circumstances? What if I am not comfortable with that?',
            answer: `If there is something you are not comfortable sharing, it's totally understandable and all right! Just let your therapist know you’d prefer not to talk about it.

However, sharing some information about yourself will help your therapist understand how best to support you during the program. You decide what that balance is.`
        },
        {
            question: 'How can I attend the call?',
            answer: `There are two ways to attend the call:
1) Via the Google Meet link in the email from “Mitsu support” in your inbox. You should have already received it; be sure to check your spam folder if you cannot find it.
2) By logging into your Mitsu.care account and navigating to the “Get to know your therapist" section. Please note that the button to join the call will be activated only 10 minutes prior to the call.`
        },
        {
            question: 'I do not want to have a video call. Can I schedule an audio call instead?',
            answer: 'Of course! If you prefer an audio call instead, you can always join while keeping your video turned off. Please inform your therapist at that time that you prefer an audio-only meeting.'
        }
    ]

    const [expandedQuestion, setExpandedQuestion] = useState<any>(null)

    const navigate = useNavigate();

    const openScheduleCall = () => {

        navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.dashboard}`);
        navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.setup_free_call}/${CLIENT_ROUTES.mood_check_in_details}`);
    }


    const intersectionRef = useRef(null);
    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: '0px',
        threshold: 1
    });



    const [isCorporate, setIsCorporate] = useState(false);

    useEffect(() => {
        const setCorporate = async () => {
            const userDetails = await getUserDetails();
            if (userDetails.isCorporateActive && userDetails.isUserActive) {
                setIsCorporate(true)
            }
        }
        setCorporate()
    })

    return <div className={style.baseDiv}>
        <div className={style.heading}>Schedule your therapist call</div>

        <div className={style.topdescription}>This 30-minute Google Meet video call with a Mitsu therapist is a critical step in your journey.</div>

        <div className={style.topdescription}>Pick a convenient time and date for only ₹<PriceText priceKey="intialCall"/>.</div>

        {
            isTwoColumn && <Button
                onClick={openScheduleCall}
                variant={'contained'}
                className={style.desktopScheduleButton}
            >
                Schedule a call
            </Button>
        }

        <div className={style.pointsheading}> Use this conversation with an experienced therapist to: </div>

        <div className={style.pointholder}>
            {
                points.map((p, i) => <div key={i} className={style.pointscontainer}>

                    <img className={style.pointsimage} src={p.icon} />
                    <div className={style.pointstitle}>
                        {p.description}
                    </div>

                </div>
                )
            }
        </div>

        {
            isTwoColumn && <Button
                onClick={openScheduleCall}
                variant={'contained'}
                className={style.desktopScheduleButton}
            >
                Schedule a call
            </Button>
        }

        <div className={style.videotitle} >Learn more about the call from Mitsu's clinical team</div>

        <div ref={intersectionRef} className={style.video} style={{ aspectRatio: aspectRatio ?? 1 }}>
            <ReactPlayer
                key={'video'}
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                url={videoUrl}
                width="100%"
                // playIcon={<PlayButton />}
                playing={!(intersection && intersection.intersectionRatio < 1)}
                height="100%"
                controls={true}
                // light={thumbUrl!}
                playsinline={true}
                onClickPreview={
                    (ev) => {
                        mixpanel.track(MixPanelEvents.therapistVideoClicked, {
                            isWebFlow: true,
                        });
                    }
                }
                onProgress={(val) => onVideoProgress(val.playedSeconds * 1000)}
            />
        </div>

        {!isCorporate && <div className={style.mitsucard}>
            <img className={style.mitsucardimage} src={TeenImage} />
            <div className={style.mitsucarddesc}>
                {isTwoColumn ? <MitsuLogo className={style.mitsucardlogo} /> : <MitsuLogoMobile className={style.mitsucardlogo} />}
                <div className={style.circleyellow}></div>
                <div className={style.price}>
                    ₹<PriceText priceKey="intialCall"/>
                </div>
                <div className={style.description}>{
                    "Choose a time convenient to you and complete your purchase to set up a call"}
                </div>

                {
                    isTwoColumn && <Button
                        onClick={openScheduleCall}
                        variant={'contained'}
                        className={style.desktopScheduleButton}
                    >
                        Schedule a call
                    </Button>
                }
            </div>
        </div>}


        <div style={{ height: isTwoColumn ? '60px' : '30px' }} ></div>
        <AdaptiveTypography variant="h2" mobileVariant="h3" className={`${style.testimonialHeader}  ${isTwoColumn ? style.alignCenter : style.alignJustify}`}>
            Frequently Asked Questions
        </AdaptiveTypography>

        <div className={style.faqBox}>
            {
                FAQS.map((faq) =>
                    <Accordion expanded={expandedQuestion == faq.question} onChange={() => expandedQuestion == faq.question ? setExpandedQuestion(null) : setExpandedQuestion(faq.question)} style={{ backgroundColor: 'transparent', boxShadow: 'unset' }}>
                        <AccordionSummary
                            style={{ padding: 0 }}
                            expandIcon={(expandedQuestion == faq.question) ? <MinusIcon /> : <PlusIcon />}
                        >
                            <Typography className={style.faqHeading} ><span className={style.faqHeading}>{faq.question}</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0, paddingBottom: '10px' }}>
                            <Typography className={style.faqContent}>
                                <span className={style.faqContent}><div dangerouslySetInnerHTML={
                                    { __html: faq.answer }}></div></span>

                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            }

        </div>
        {
            isTwoColumn && <div className={style.faqandSchedule}>
                <Button
                    onClick={openScheduleCall}
                    variant={'contained'}
                    className={style.desktopScheduleButton}
                >
                    Schedule a call
                </Button>

                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/9987806854?text=Hello,%20I%20am%20interested%20in%20Mitsu%27s%20program.%20How%20can%20I%20learn%20more"
                >
                    <Button variant='outlined' className={style.contactUs}>
                        <WhatsAppOutlinedImg />

                        Talk with us
                    </Button>
                </a>
            </div>
        }
        <div style={{ height: '100px' }} />
        {!isTwoColumn && <div
            className={style.mobileschedulecall}>

            <Button
                onClick={openScheduleCall}
                variant={'contained'}
            >
                Schedule a call
            </Button>
        </div>
        }
    </div>
}

export default CallExplainerScreen;