import React, { useContext, useEffect, useState } from 'react';
import Button from '../../components/base/button';
import { AppContext } from '../../helpers/hooks/AppContext';
import notify from '../../helpers/toastify-helper';
import { CLIENT_ROUTES } from '../../router/routes';
import { emailVerification, sendEmailVerification } from '../../services/users.service';
import css from './index.module.scss';
import { AdaptiveTypography } from '../../components/base';
import { MixPanelEvents } from '../../helpers/constant';

const PaymentPendingPage: React.FC<{ isFailed: boolean }> = ({ isFailed }) => {
  const { navigate, mixpanel } = useContext(AppContext);

  useEffect(
    () => {
      try {
        mixpanel.track(MixPanelEvents.paymentReturned, {
          isWebFlow: true,
        });
      } catch (error) {
        //
      }
    }, []
  );
  return (
    <section className={css.container}>
      <div className={css.headerImage}>
        <div className={`${css.imageDiv} ${isFailed ? css.failed : css.pending}`} />
      </div>
      <div className={css.spacer} />
      <div style={{ height: '20px' }} />

      <div className={css.pageCenter}>
        <div className={css.mainPage}>
          <div className={`${css.pageHeadContainer}`}>
            <AdaptiveTypography variant="h2" mobileVariant="h3">
              {isFailed ? 'Your payment was unsuccessful' : 'Please wait'}
            </AdaptiveTypography>
            <br />

            <AdaptiveTypography variant="subheading2" className={css.textColorGrey400}>
              {
                isFailed ? <span>Please check your details and try again.</span> : <span>
                  Your payment is being processed...
                  <br></br>
                  Please check back after some time.
                </span>
              }
            </AdaptiveTypography>
          </div>
        </div>
      </div>
      <div className={css.spacer} />
      <div style={{ height: '40px' }} />
      <div className={css.spacer} />

      <div className={css.buttonRow}>
        <Button onClick={() => {
          navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.dashboard}`)
        }} className={css.singleMainButton}>
          Continue
        </Button>
      </div>
    </section>
  );
};

export default PaymentPendingPage;
