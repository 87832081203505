import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Typography } from '../../components/base';
import Button from '../../components/base/button';
import { CLIENT_ROUTES } from '../../router/routes';
import style from './checkinQuestions.module.scss';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../helpers/hooks/AppContext';
import Rand from 'rand-seed';

const CheckInInstructionsPage = () => {
  const navigate = useNavigate();
  const onNext = () => {
    navigate(`../${CLIENT_ROUTES.mood_check_in_questionaire}`);
  };
  const theme = useTheme();
  const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));



  return (
    <div className={style.questionsPageContainer}>
      <div className={style.questionsPageCenter}>
        <div className={style.questionsPage}>
          <div className={`${style.questionsHeadContainer} ${isTwoColumn ? '' : style.alignJustify}`}>
            <Typography variant="h1">Mood check-in</Typography>
            <Typography variant="subheading1">
              Self-reflection is an important first step in your journey toward lifelong mental well-being! This short questionnaire will help you assess your current emotional health and set goals for yourself.

            </Typography>
            <div className={style.instructionBox}>
              <Typography variant="h3"> About the check-in: </Typography>
              <div className={style.instructionContent}>
                <ul>
                  <li>
                    <Typography variant="body2">
                      {' '}
                      This questionnaire is drawn from PHQ-9 and GAD-7, two scientific assessments that are used by clinicians globally for screening of anxiety and depression.
                    </Typography>
                  </li>
                  <br />
                  <li>
                    <Typography variant="body2">
                      {' '}
                      The check-in will take roughly 5 mins to complete. Please read each question carefully and answer according to how you feel.

                    </Typography>
                  </li>
                  <br />
                  <li>
                    <Typography variant="body2">
                      {' '}
                      We understand the importance of confidentiality. Your responses will remain confidential and will only be shared with your dedicated Mitsu therapist, if you proceed with the next steps.
                    </Typography>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className={style.spacer} />
          <div style={{ height: '40px' }} />
        </div>
      </div>
      <div className={style.buttonRow}>
        <Button variant="contained" onClick={onNext} >
          Start check-in
        </Button>
      </div>
    </div>
  );
};

export default CheckInInstructionsPage;
