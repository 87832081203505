import React from 'react';
import { SVGProps } from '../../helpers/types';

const WhatsAppImg: React.FC<SVGProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.279 1.46253C6.5446 1.46253 1.88147 6.08994 1.88147 11.7797C1.88147 14.036 2.61656 16.1272 3.86172 17.8286L2.56332 21.6594L6.55818 20.39C8.19947 21.467 10.1661 22.0957 12.279 22.0957C18.011 22.0957 22.6756 17.4679 22.6756 11.7797C22.6756 6.08994 18.011 1.46253 12.279 1.46253Z"
        fill="url(#paint0_linear_4616_2995)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1679 14.384C18.095 14.2644 17.9023 14.1926 17.6147 14.0493C17.3256 13.9063 15.905 13.2123 15.6406 13.1174C15.3763 13.022 15.1823 12.974 14.991 13.2603C14.7983 13.5471 14.2446 14.1926 14.0757 14.384C13.9076 14.5757 13.7396 14.599 13.4507 14.4557C13.1614 14.3127 12.2303 14.009 11.1277 13.034C10.2697 12.2733 9.69003 11.336 9.52067 11.0494C9.353 10.763 9.50399 10.608 9.64702 10.465C9.7773 10.336 9.93634 10.1303 10.0806 9.96367C10.2253 9.79566 10.2734 9.67569 10.3697 9.48437C10.466 9.29297 10.418 9.12638 10.345 8.98302C10.2734 8.83999 9.69531 7.42968 9.45436 6.85531C9.215 6.2827 8.97397 6.30408 8.8047 6.30408C8.63669 6.30408 8.32498 6.35396 8.32498 6.35396C8.32498 6.35396 7.746 6.42564 7.48166 6.71236C7.21732 6.99867 6.47133 7.69268 6.47133 9.10165C6.47133 10.5117 7.5053 11.875 7.64967 12.065C7.79429 12.2567 9.64702 15.2447 12.5833 16.392C15.5196 17.539 15.5196 17.1563 16.0494 17.108C16.5779 17.0614 17.7577 16.4153 17.9987 15.746C18.2397 15.0767 18.2397 14.5027 18.1679 14.384ZM12.2186 1.86203C6.75529 1.86203 2.31266 6.27071 2.31266 11.6913C2.31266 13.841 3.01304 15.8334 4.19934 17.4544L2.96231 21.104L6.76837 19.8946C8.33202 20.9206 10.2056 21.5197 12.2186 21.5197C17.6797 21.5197 22.1236 17.1106 22.1236 11.6913C22.1236 6.27071 17.6797 1.86203 12.2186 1.86203ZM24 11.6913C24 18.147 18.7254 23.3817 12.2186 23.3817C10.1523 23.3817 8.21096 22.854 6.52331 21.9269L0 24L2.12637 17.7279C1.05333 15.966 0.436374 13.8997 0.436374 11.6913C0.436374 5.23431 5.71101 0 12.2186 0C18.7254 0 24 5.23431 24 11.6913Z"
        fill="white"
      />
      <defs>
        <linearGradient id="paint0_linear_4616_2995" x1="12" y1="24" x2="12" y2="0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#21B04C" />
          <stop offset="1" stopColor="#128C49" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WhatsAppImg;
