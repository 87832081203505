import React from 'react';
import { SVGProps } from '../../helpers/types';

const WhatsAppOutlinedImg: React.FC<SVGProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_464_147)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6411 2.32533C12.1438 0.826667 10.1531 0.000666667 8.03244 0C3.66244 0 0.105776 3.556 0.103776 7.928C0.103109 9.32533 0.468443 10.6893 1.16244 11.892L0.0371094 16L4.23978 14.8973C5.39778 15.5293 6.70177 15.862 8.02844 15.8627H8.03177C12.4011 15.8627 15.9584 12.306 15.9604 7.934C15.9618 5.816 15.1378 3.82333 13.6411 2.32533ZM8.03244 14.5233H8.02977C6.84711 14.5233 5.68778 14.2053 4.67578 13.6047L4.43511 13.462L1.94111 14.116L2.60711 11.684L2.45044 11.4347C1.79044 10.3853 1.44244 9.17267 1.44311 7.928C1.44444 4.29467 4.40111 1.33867 8.03578 1.33867C9.79577 1.33867 11.4504 2.02533 12.6944 3.27067C13.9384 4.51667 14.6231 6.172 14.6224 7.93267C14.6204 11.5673 11.6644 14.5233 8.03244 14.5233ZM11.6471 9.588C11.4491 9.48867 10.4751 9.00933 10.2931 8.94333C10.1118 8.87733 9.97977 8.844 9.84711 9.042C9.71444 9.24 9.33577 9.68667 9.21978 9.81933C9.10444 9.95133 8.98844 9.968 8.79044 9.86867C8.59244 9.76933 7.95377 9.56067 7.19711 8.88533C6.60844 8.36 6.21044 7.71133 6.09511 7.51267C5.97977 7.314 6.08311 7.20733 6.18178 7.10867C6.27111 7.02 6.37978 6.87733 6.47911 6.76133C6.57911 6.64667 6.61177 6.564 6.67844 6.43133C6.74444 6.29933 6.71178 6.18333 6.66178 6.084C6.61178 5.98533 6.21578 5.01 6.05111 4.61333C5.89044 4.22667 5.72711 4.27933 5.60511 4.27333C5.48978 4.26733 5.35778 4.26667 5.22511 4.26667C5.09311 4.26667 4.87844 4.316 4.69711 4.51467C4.51578 4.71333 4.00378 5.19267 4.00378 6.16733C4.00378 7.14267 4.71378 8.08467 4.81244 8.21667C4.91111 8.34867 6.20911 10.35 8.19644 11.208C8.66911 11.412 9.03844 11.534 9.32577 11.6253C9.80044 11.776 10.2324 11.7547 10.5738 11.704C10.9544 11.6473 11.7458 11.2247 11.9111 10.762C12.0764 10.2993 12.0764 9.902 12.0264 9.82C11.9771 9.73667 11.8451 9.68733 11.6471 9.588Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_464_147">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhatsAppOutlinedImg;
