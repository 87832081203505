import { SERVER_CONFIG } from '../helpers/constant';
import httpClient from './index.service';

export async function addNewUser(requestBody: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.users}`, requestBody);
    return response;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}

export async function emailVerification(requestBody: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.users}/${SERVER_CONFIG.emailVerification}`, requestBody);
    return response;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}

export async function sendEmailVerification() {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.users}/${SERVER_CONFIG.sendEmailVerification}`, {
      mobile: localStorage.getItem('mobile'),
      name: localStorage.getItem('name'),
      email: localStorage.getItem('email'),
    });
    return response;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}

export async function verifyEmailOTP(otp: string) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.users}/${SERVER_CONFIG.verifyEmailOTP}`, {
      otp: otp,
      userId: localStorage.getItem('userId'),
    });
    return response;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}


export const getUserSteps = async (id: string) => {
  try {
    const response = await httpClient.get(`/users/step?id=${id}`);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getMitsuVideoName = async () => {
  try {
    const response = await httpClient.get(`/programs/mitsu-intro`);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getMitsuVideo = async (name: string) => {
  try {
    const response = await httpClient.get(`/file/video?name=${name}`);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postCompletedStep = async (id: any, requestBody: any) => {
  try {
    const response = await httpClient.patch(`/users/step?id=${id}`, requestBody);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
