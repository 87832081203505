import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';
import './App.scss';
import Header from './components/common/header/index';
import AppContextProvider from './helpers/hooks/AppContext';
import RoutesComp from './router';
import ScrollToTopOnNavigate from './helpers/hooks/useScrollTop';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module'
import ReactGA from "react-ga4";


function App() {
  useEffect(
    ()=>{
      const tagManagerArgs = {
        gtmId: 'GTM-KPWPX22C'
    }
    
    TagManager.initialize(tagManagerArgs)
    ReactGA.initialize("G-0P704BEE5D");

    }
  )
  return (
    <div className="app">
      <HelmetProvider>
        <Router>
          <ScrollToTopOnNavigate />
          <AppContextProvider>
            <Header />
            <RoutesComp />
            <ToastContainer
              autoClose={4000}
              position="top-center"
              closeButton={false}
              hideProgressBar
              pauseOnFocusLoss={false}
              closeOnClick={false}
              limit={3}
            />
          </AppContextProvider>
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;
