import { useEffect, useState } from 'react';
import { getQuestions } from '../../services/selfcheckin.service';
import CheckinQuestionsRouter from './chekinQuestionsRouter';

const SelfCheckinQuestionaire = () => {
  const [questions, setQuestions] = useState();
  useEffect(() => {
    getCheckinQuestions();
  }, []);

  const getCheckinQuestions = async () => {
    const data = await getQuestions();
    setQuestions(data);
  };

  if (questions != null) {
    return <CheckinQuestionsRouter questions={questions} />;
  } else {
    return <div>Loading....</div>;
  }
};
export default SelfCheckinQuestionaire;
