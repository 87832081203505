import React from 'react';
import { SVGProps } from '../../helpers/types';

const QuoteIcon: React.FC<SVGProps> = () => {
  return (
    <svg width="37" height="30" viewBox="0 0 37 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.8516 29.1641C27.375 29.1641 26.0859 28.918 24.9844 28.4258C23.9062 27.9102 23.0156 27.2305 22.3125 26.3867C21.6094 25.543 21.082 24.5586 20.7305 23.4336C20.3789 22.3086 20.2031 21.1133 20.2031 19.8477C20.2031 17.3164 20.5781 15.0312 21.3281 12.9922C22.1016 10.9297 23.0977 9.11328 24.3164 7.54297C25.5352 5.94922 26.8828 4.58984 28.3594 3.46484C29.8594 2.31641 31.3359 1.40234 32.7891 0.722656L33.8086 2.09375C31.7461 4.03906 30.1055 5.77344 28.8867 7.29688C27.668 8.79688 27.0586 10.0508 27.0586 11.0586C27.0586 11.668 27.3164 12.1602 27.832 12.5352C28.3477 12.9102 28.9805 13.25 29.7305 13.5547C30.457 13.8359 31.2188 14.1523 32.0156 14.5039C32.8125 14.8555 33.5391 15.3242 34.1953 15.9102C34.875 16.4727 35.4258 17.1875 35.8477 18.0547C36.293 18.9219 36.5156 20.0234 36.5156 21.3594C36.5156 22.1563 36.3516 23.0117 36.0234 23.9258C35.7188 24.8398 35.2383 25.6836 34.582 26.457C33.9492 27.2305 33.1523 27.875 32.1914 28.3906C31.2305 28.9062 30.1172 29.1641 28.8516 29.1641ZM9.51562 29.1641C8.03906 29.1641 6.75 28.918 5.64844 28.4258C4.57031 27.9102 3.67969 27.2305 2.97656 26.3867C2.27344 25.543 1.74609 24.5586 1.39453 23.4336C1.04297 22.3086 0.867188 21.1133 0.867188 19.8477C0.867188 17.3164 1.24219 15.0312 1.99219 12.9922C2.76562 10.9297 3.76172 9.11328 4.98047 7.54297C6.19922 5.94922 7.54688 4.58984 9.02344 3.46484C10.5234 2.31641 12 1.40234 13.4531 0.722656L14.4727 2.09375C12.4102 4.03906 10.7695 5.77344 9.55078 7.29688C8.33203 8.79688 7.72266 10.0508 7.72266 11.0586C7.72266 11.668 7.98047 12.1602 8.49609 12.5352C9.01172 12.9102 9.64453 13.25 10.3945 13.5547C11.1211 13.8359 11.8828 14.1523 12.6797 14.5039C13.4766 14.8555 14.2031 15.3242 14.8594 15.9102C15.5391 16.4727 16.0898 17.1875 16.5117 18.0547C16.957 18.9219 17.1797 20.0234 17.1797 21.3594C17.1797 22.1563 17.0156 23.0117 16.6875 23.9258C16.3828 24.8398 15.9023 25.6836 15.2461 26.457C14.6133 27.2305 13.8164 27.875 12.8555 28.3906C11.8945 28.9062 10.7812 29.1641 9.51562 29.1641Z" fill="#F37A3C" />
    </svg>

  );
};

export default QuoteIcon;
