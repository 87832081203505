import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useReducer, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography } from '../../components/base';
import Checkbox from '../../components/base/checkbox/checkbox';
import Radio from '../../components/base/radio';
import style from './checkinQuestions.module.scss';
import { TextField } from '../../components/common';
import { OPTION_CONSTANTS } from '../../helpers/constant';

const CheckinQuestionPage = ({ question, isReview, progress, total }: { question: any; isReview: boolean, progress: number, total: number }) => {
  const theme = useTheme();
  const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));
  const isMultiChoice = question.options_type == OPTION_CONSTANTS.Multiple;
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [otherValue, setOtherValue] = useState('')

  const { register, setValue, getValues } = useFormContext();

  useEffect(() => {
    if (question.userAnswers) {
      setValue(question.id, isMultiChoice ? question.userAnswers.split('|') : question.userAnswers);
      forceUpdate();
    }

    if (isMultiChoice && Array.isArray(getValues(question.id))) {
      const otherVal = getValues(question.id).find((val: any) => !Object.keys(question.options).includes(val))
      if (otherVal) {
        setOtherValue(otherVal)
      }
    }
  }, []);

  const toggleSelected = (val: any) => {
    if (isReview) {
      return;
    }
    if (isMultiChoice) {
      if (getValues(question.id) === false) {
        setValue(question.id, [val]);
        forceUpdate();
      } else if (!getValues(question.id).includes(val)) {
        setValue(question.id, [...getValues(question.id), val]);
        forceUpdate();
      } else {
        const selected = getValues(question.id);
        selected.splice(selected.indexOf(val), 1);
        setValue(question.id, selected, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true,
        });
        forceUpdate();
      }
    } else {
      setValue(question.id, val, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });
      forceUpdate();
    }
  };

  const changeOtherVal = (val: string) => {
    if (isReview) {
      return
    }
    if (otherValue) {
      toggleSelected(otherValue)
    }
    if (val) {
      toggleSelected(val)
    }
    setOtherValue(val)

  }

  return (
    <div className={`${style.questionsHeadContainer} ${isTwoColumn ? '' : style.alignJustify}`}>
      <Typography variant="h1">{question.heading}</Typography>
      <div style={{ height: '8px' }} />

      <Typography variant="subheading1" dangerouslySetInnerHTML={{ __html: question.description }} >

      </Typography>
      <div
        className={`${style.questionsAndOptionsContainer} ${isTwoColumn ? '' : style.alignJustify} ${isTwoColumn ? '' : style.alignStretch
          }`}
      >

        <Typography variant="h2">{question.title}</Typography>

        <div className={`${style.optionsGrid} ${isTwoColumn ? style.grid_2 : style.grid_1} `}>
          {Object.keys(question.options).map((option: any) => {
            if (isMultiChoice && option == OPTION_CONSTANTS.Others) {
              return (
                <Typography key={option} variant="subheading2">
                  <input disabled={isReview} onChange={(ev) => changeOtherVal(ev.target.value)} value={otherValue} placeholder={option} className={`${style.option} ${style.optionField}`} />
                  <div style={{ display: 'none' }} >
                    <Checkbox
                      {...register(question.id, { required: true })}
                      value={otherValue}
                      required
                      checked={!!otherValue}
                    />
                  </div>
                </Typography>
              )
            } else {
              return (
                <div onClick={() => toggleSelected(option)} key={option} className={`${style.option}`} aria-hidden>
                  <Typography variant="subheading2">{option}</Typography>
                  <div className={style.spacer} style={{ minWidth: '20px' }} />

                  {isMultiChoice ? (
                    <Checkbox
                      {...register(question.id, { required: true })}
                      value={option}
                      required
                      disabled={isReview && !(getValues(question.id) != false && getValues(question.id)?.includes(option))}
                      checked={getValues(question.id) != false && getValues(question.id)?.includes(option)}
                    />
                  ) : (
                    <Radio
                      {...register(question.id, { required: true })}
                      value={option}
                      disabled={isReview && !(getValues(question.id) == option)}
                      checked={getValues(question.id) == option}
                    />
                  )}
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default CheckinQuestionPage;
