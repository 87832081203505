import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { CLIENT_ROUTES } from "../../router/routes";
import { useEffect } from "react";


const OrderStatusPage = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    let orderId = searchParams.get('order_id')

    if (orderId) {
        return <Navigate to={`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.setup_free_call}/${CLIENT_ROUTES.scheduled_call}?order_id=${orderId}`} replace />
    } else {
        return <Navigate to={`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.dashboard}`} replace />
    }
}

export default OrderStatusPage;
