import React from 'react';
import { SVGProps } from '../../helpers/types';

const LockIcon: React.FC<SVGProps> = () => {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon">
        <path
          id="Vector"
          d="M6.45837 23.8335C6.00699 23.8335 5.6233 23.6755 5.30733 23.3595C4.99136 23.0436 4.83337 22.6599 4.83337 22.2085V10.4543C4.83337 10.0029 4.99136 9.61926 5.30733 9.30329C5.6233 8.98732 6.00699 8.82933 6.45837 8.82933H8.35421V6.22933C8.35421 4.80294 8.85525 3.5887 9.85733 2.58662C10.8594 1.58454 12.0737 1.0835 13.5 1.0835C14.9264 1.0835 16.1407 1.58454 17.1427 2.58662C18.1448 3.5887 18.6459 4.80294 18.6459 6.22933V8.82933H20.5417C20.9931 8.82933 21.3768 8.98732 21.6927 9.30329C22.0087 9.61926 22.1667 10.0029 22.1667 10.4543V22.2085C22.1667 22.6599 22.0087 23.0436 21.6927 23.3595C21.3768 23.6755 20.9931 23.8335 20.5417 23.8335H6.45837ZM13.5 18.4168C14.0778 18.4168 14.5698 18.2182 14.9761 17.821C15.3823 17.4238 15.5855 16.9453 15.5855 16.3856C15.5855 15.8439 15.3823 15.3519 14.9761 14.9095C14.5698 14.4672 14.0778 14.246 13.5 14.246C12.9223 14.246 12.4302 14.4672 12.024 14.9095C11.6177 15.3519 11.4146 15.8439 11.4146 16.3856C11.4146 16.9453 11.6177 17.4238 12.024 17.821C12.4302 18.2182 12.9223 18.4168 13.5 18.4168ZM9.97921 8.82933H17.0209V6.22933C17.0209 5.25433 16.6778 4.42377 15.9917 3.73766C15.3056 3.05155 14.475 2.7085 13.5 2.7085C12.525 2.7085 11.6945 3.05155 11.0084 3.73766C10.3223 4.42377 9.97921 5.25433 9.97921 6.22933V8.82933Z"
          fill="#203B54"
        />
      </g>
    </svg>
  );
};

export default LockIcon;
