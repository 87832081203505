/* eslint-disable jsx-a11y/no-autofocus */
import { Input } from '@mui/material';
import React, { ChangeEvent, useMemo } from 'react';
import { ASCII_KEYS, DIGIT_REGEX } from '../../../helpers/constant';
import styles from './index.module.scss';
interface OtpInputProps {
  onChange: (value: string) => void;
  value: string;
  valueLength?: number;
  error?: boolean;
  onEnterKeyDown?: () => void;
  disabled?: boolean;
  centered?: boolean,
}

const OtpInput = ({
  onChange,
  valueLength = 4,
  value,
  error = false,
  onEnterKeyDown,
  disabled = false,
  centered,
}: OtpInputProps) => {
  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    return Array.from({ length: valueLength }, (_, index) =>
      DIGIT_REGEX.test(valueArray[index]) ? valueArray[index] : '',
    );
  }, [value, valueLength]);

  const focusToInput = (target: HTMLElement, isNext?: boolean) => {
    const [elementSibling] = Array.from(
      target.parentElement?.[isNext ? 'nextElementSibling' : 'previousElementSibling']?.children || [],
    ) as HTMLInputElement[];

    elementSibling && elementSibling.focus();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const inputValue = event?.currentTarget?.value;
    const inputValueLength = inputValue.length;

    const isInputValueDigit = DIGIT_REGEX.test(inputValue);

    if (!isInputValueDigit && inputValue) {
      return;
    }

    if (inputValueLength <= 1) {
      const items = [...valueItems];
      items.splice(index, 1, isInputValueDigit || index === value.length - 1 ? inputValue : ' ');

      onChange(items.join(''));

      inputValue && focusToInput(event.currentTarget, true);
    } else if (inputValueLength === valueLength) {
      onChange(inputValue);
      event.currentTarget.blur();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.currentTarget as HTMLInputElement;
    const { arrowLeft, arrowRight, arrowDown, backspace, arrowUp, enter } = ASCII_KEYS;

    target.setSelectionRange(0, target.value.length);

    switch (event.key) {
      case arrowRight:
        break;
      case arrowDown:
        event.preventDefault();
        focusToInput(target, true);
        break;
      case arrowLeft:
        break;
      case arrowUp:
        event.preventDefault();
        focusToInput(target);
        break;
      case backspace:
        if (!target.value) {
          focusToInput(target);
        }
        break;
      case enter:
        value.length === valueLength && onEnterKeyDown?.();
        break;
      default:
        break;
    }
  };

  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { currentTarget } = event;

    const [prevInputEl] = Array.from(
      currentTarget.parentElement?.previousElementSibling?.children || [],
    ) as HTMLInputElement[];

    if (prevInputEl && !prevInputEl.value) {
      prevInputEl.focus();
      return;
    }

    currentTarget.setSelectionRange(0, currentTarget.value.length);
  };

  return (
    <section className={styles.otpContainer}>
      <div className={[styles.otpInputContainer, centered ? styles.centered : ''].join(' ')}>
        {valueItems?.map((digit, index) => (
          <Input
            disableUnderline
            key={index}
            type="password"
            inputMode="numeric"
            autoComplete="one-time-code"
            inputProps={{
              pattern: '[0-9]*',
              inputMode: 'numeric',
            }}
            className={[styles.otpInput, disabled ? styles.disabled : '', error ? styles.error : ''].join(' ')}
            value={digit}
            onChange={(event) => handleInputChange(event, index)}
            error={error}
            onKeyDown={handleKeyDown}
            onFocus={handleInputFocus}
            autoFocus={index === 0}
            disabled={disabled}
          />
        ))}
      </div>
    </section>
  );
};

export default OtpInput;
