import { useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import ChevronLeft from '../../assets/icons/ChevronLeft';
import Button from '../../components/base/button';
import CheckinQuestionPage from './checkinQuestion.page';
import style from './checkinQuestions.module.scss';

import { AppContext } from '../../helpers/hooks/AppContext';

import { CLIENT_ROUTES } from '../../router/routes';
import { answerCheckinQuestion, getCurrentUserId } from '../../services/selfcheckin.service';
import './animation.css';
import notify from '../../helpers/toastify-helper';
import { getUserSteps } from '../../services/users.service';
import { Typography } from '../../components/base';
import Rand from 'rand-seed'
import { MixPanelEvents } from '../../helpers/constant';

const CheckinQuestionsRouter = ({ questions }: { questions: Array<any> }) => {

  const formMethods = useForm();

  const theme = useTheme();
  const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));

  const navigate = useNavigate();
  const isBase = !!useMatch(
    `/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.mood_check_in}/${CLIENT_ROUTES.mood_check_in_questionaire}/:id`,
  );

  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState('fadeIn');

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage('fadeOut');
  }, [location, displayLocation]);


  const { appState, appDispatch } = useContext(AppContext);
  const [isReview, setIsReview] = useState(appState.stepsData?.stepsCompleted && appState.stepsData?.stepsCompleted > 1)

  useEffect(
    () => {
      const getStepsCompleted = async () => {
        const userId = await getCurrentUserId();
        const response = await getUserSteps(`${userId}`);

        if (response?.data) {
          const { data } = response;
          appDispatch({
            type: 'setStepsData',
            payload: {
              stepsData: {
                stepsCompleted: data.stepsCompleted,
              },
            },
          });
          setIsReview(data.stepsCompleted > 1);
        }
      };
      if (!appState.stepsData?.stepsCompleted) {
        getStepsCompleted()
      }
    }, []
  )

  let currentQuestionId: any;
  const regex = new RegExp('check-in-questionaire\\/?(\\d+)?$', 'gm');
  const results = regex.exec(location.pathname);
  const questionIndex = results?.[1];
  if (!questionIndex) {
    currentQuestionId = questions.at(0).id;
  } else {
    const currentQuestion = questions.find((question) => question.index == questionIndex)
    if (currentQuestion == null) {
      return <Navigate to={`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.mood_check_in}/${CLIENT_ROUTES.mood_check_in_questionaire}`} replace />
    }
    currentQuestionId = currentQuestion.id;
  }

  const isAnswered = formMethods.getValues()[currentQuestionId] && (!Array.isArray(formMethods.getValues()[currentQuestionId]) || formMethods.getValues()[currentQuestionId].length >= 1);


  const progress = questions.findIndex((q) => q.id == currentQuestionId) ?? 0

  const total = questions.length


  const onNextClick = async (data: any) => {
    try {
      const ninthIndex = questions.findIndex((question) => question.index == 9)
      const applicableQuestions = (ninthIndex >= 0 && !isReview) ?
        data[questions[ninthIndex].id] == 'Not at all' ?
          questions.filter((e, i) => {
            const isSkipping = !(i >= ninthIndex + 1 && i <= ninthIndex + 3)
            if (isSkipping) {
            }
            return isSkipping
          })
          : questions
        : questions;


      let nextQuestion;
      const currentIndex = applicableQuestions.findIndex((question) => question.id == currentQuestionId);

      if (!isReview) {
        const answer = data[currentQuestionId];
        const answers = Array.isArray(answer) ? answer : [answer];
        await answerCheckinQuestion(currentQuestionId, answers);
      }
      if (currentIndex < applicableQuestions.length - 1) {
        let values = formMethods.getValues();
        nextQuestion = applicableQuestions.find(
          (question) => {
            let isAnswered = values[question.id] && (!Array.isArray(values[question.id]) || values[question.id].length >= 1)
            return !isAnswered
          }
        )
      }

      if (nextQuestion) {
        if (!isBase) {
          navigate(`./${nextQuestion.index}`, { relative: 'path' });
        } else {
          navigate(`../${nextQuestion.index}`, { relative: 'path' });
        }
      } else {
        navigate(`../${CLIENT_ROUTES.mood_check_in_summary}`);
      }
    } catch (error: any) {
      if (error?.statusCode && error?.statusCode == 400) {
        notify({
          message: error.message,
          severity: "error"
        })
        window.location.href = (`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.mood_check_in}/${CLIENT_ROUTES.mood_check_in_questionaire}`)
      }
    }
  };

  try {

    const { mixpanel } = useContext(AppContext);

    useEffect(() => {

      mixpanel.track(MixPanelEvents.checkinStarted, {
        'Week Number': '0',
        isWebFlow: true,
      });
    }, [])
  } catch (error) {
    //ignore
  }


  const onBackClick = () => {
    navigate(-1);
  };

  formMethods.watch();

  return (
    <div className={style.questionsPageContainer}>
      <div className={style.questionsPageCenter}>
        <div className={style.questionsPage}>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onNextClick)}>
              <div
                className={`${transitionStage}`}
                onAnimationEnd={() => {
                  if (transitionStage === 'fadeOut') {
                    setTransistionStage('fadeIn');
                    setDisplayLocation(location);
                  }
                }}
              >
                <Routes location={displayLocation}>
                  {questions.map((question, index) => (
                    <Route
                      index={index == 1}
                      key={question.id}
                      path={`/${index == 0 ? '*' : question.index}`}
                      element={<CheckinQuestionPage key={question.id} isReview={isReview} question={question} progress={index} total={questions.length} />}
                    />
                  ))}
                </Routes>
              </div>
            </form>
          </FormProvider>
          <div className={style.spacer} />
        </div>
      </div>

      <div className={style.questionsProgressAligner}>
        <div className={style.questionProgress}>
          <div
            className={style.questionsProgressBarContainer}>
            <div className={style.questionsProgressBar} style={{ width: `${progress * 100 / total}%` }} />
          </div>
          <Typography className={style.questionsProgressText}>
            {progress} / {total} completed
          </Typography>
        </div>
      </div>
      <div className={style.buttonRow}>
        <Button
          onClick={onBackClick}
          variant={isTwoColumn ? 'outlined-nopad' : 'text-noshadow'}
          className={`${style.buttonOutlined} ${isTwoColumn ? '' : style.mobileButton}`}
        >
          <div className={style.buttonContent}>
            <ChevronLeft />
            <span style={{ width: '10px' }} />
            Previous
          </div>
        </Button>
        <span style={{ width: '24px' }} />
        <Button disabled={!isAnswered} variant="contained-nopad" onClick={formMethods.handleSubmit(onNextClick)}>
          <div className={style.buttonContent}>
            Next
          </div>
        </Button>
      </div>
    </div>
  );
};

export default CheckinQuestionsRouter;
