import { useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdaptiveTypography, Button, Typography } from '../../components/base';
import Image from '../../components/base/image';
import { AppContext } from '../../helpers/hooks/AppContext';
import { CLIENT_ROUTES } from '../../router/routes';
import { getTherapistInfo } from '../../services/callSchedule.service';
import { getCurrentUserId } from '../../services/selfcheckin.service';
import { getUserSteps } from '../../services/users.service';
import style from './scheduleCall.module.scss';

const TherapistDetailsPage = () => {
  const [_isButtonEnabled, setButtonEnabled] = useState(false);
  const theme = useTheme();
  const isTwoColumn = useMediaQuery(theme.breakpoints.up('sm'));

  const [therapistInfo, setTherapistInfo] = useState<any>(null);
  const { appState, appDispatch } = useContext(AppContext);

  const [isCallCompleted, setIsCallCompleted] = useState(
    appState.stepsData?.stepsCompleted && appState.stepsData?.stepsCompleted >= 4,
  );

  const navigate = useNavigate();

  useEffect(() => {
    const getTherapistDetails = async () => {
      const info = await getTherapistInfo();
      setTherapistInfo(info);
      const meetTime = moment(info.startDateTime);
      const currentTime = moment();
      if (meetTime.isAfter(currentTime) && currentTime.add('1', 'day').isAfter(meetTime)) {
        const timeDiff = meetTime.diff(currentTime);
        setTimeout(() => {
          setButtonEnabled(true);
        }, timeDiff);
      }
    };

    const getStepsCompleted = async () => {
      const userId = await getCurrentUserId();
      const response = await getUserSteps(`${userId}`);

      if (response?.data) {
        const { data } = response;
        appDispatch({
          type: 'setStepsData',
          payload: {
            stepsData: {
              stepsCompleted: data.stepsCompleted,
            },
          },
        });
        setIsCallCompleted(data.stepsCompleted >= 4);
      }
    };

    getTherapistDetails();
    if (!appState.stepsData?.stepsCompleted) {
      getStepsCompleted();
    }
  }, []);

  const onClick = () => {
    window.open(therapistInfo.meet_links.google_link ?? therapistInfo.meet_links.zoom_link, '_blank', 'noreferrer');
  };

  const onReschedule = () => {
    navigate(`../${CLIENT_ROUTES.scheduled_call}`);
  };

  const onEnrolClick = () => {
    navigate(`../../${CLIENT_ROUTES.dashboard}`);
    navigate(`../../${CLIENT_ROUTES.enroll}`);
  };

  const isButtonActive = therapistInfo && moment().isAfter(moment(therapistInfo.startDateTime));

  return (
    <div className={style.scheduleCallContainer}>
      <div className={`${style.scheduleCallPageCenter}`}>
        <div className={style.ScheduleCallPage}>
          <div className={`${style.scheduleCallHeadContainer}  ${isTwoColumn ? '' : style.alignJustify}`}>
            <AdaptiveTypography variant="h1" mobileVariant="h3">
              Get to Know Your Therapist
            </AdaptiveTypography>
            {therapistInfo && (
              <div className={style.therapistInfoBlock}>
                <Image src={therapistInfo.picture} className={style.therapistImage} />
                <AdaptiveTypography variant="h4">{therapistInfo.name}</AdaptiveTypography>
                <AdaptiveTypography variant="label">{therapistInfo.education}</AdaptiveTypography>
              </div>
            )}
          </div>
          <div style={{ height: '20px' }} />

          <div className={`${style.scheduleCallBody} ${style.breakSpaces} ${isTwoColumn ? '' : style.alignJustify}`}>
            <Typography variant="h2">Introduction</Typography>
            <div style={{ height: '10px' }} />
            <Typography variant="subheading2" className={style.textColorGrey400}>
              {therapistInfo?.introduction}
            </Typography>
          </div>

          <div className={style.spacer} />
          <div style={{ height: '40px' }} />
        </div>
      </div>

      <div className={style.buttonRow}>
        {isCallCompleted ? (
          <Button
            variant="contained"
            onClick={onEnrolClick}
          >
            <div className={style.singleMainButtonContent}>Enrol in Program</div>
          </Button>
        ) : (
          therapistInfo && (
            <Button
              variant="contained"
              onClick={onClick}
              disabled={!isButtonActive}
              className={`${style.meetButton}`}
            >
              <div>
                {' '}
                Meet on {moment(therapistInfo.startDateTime).local().format('Do MMM')} at{' '}
                {moment(therapistInfo.startDateTime).local().format('h:mm A')}{' '}
              </div>
            </Button>
          )
        )}
      </div>

      {!isCallCompleted && (
        <div className={style.rescheduleText} style={{ cursor: 'pointer' }}>
          <Typography variant="body2" onClick={onReschedule} >
            {' '}
            Re-schedule Call?{' '}
            <span className={style.rescheduleButton} aria-hidden>
              Click here
            </span>{' '}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TherapistDetailsPage;
