import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../components/common/not-found';
import PrivateRoutes from './private-routes';
import ProtectedRoute from './protected-routes';
import routes from './routes';

export interface IRoutesProps {
  path: string;
  element: React.ReactNode;
  children?: IRoutesProps[];
}

const RoutesComp: React.FC = () => {
  const childrenRoutes = (routes: IRoutesProps[]) => {
    return routes?.map((route, index) => {
      if (route.children)
        return (
          <Route path={route.path} element={route.element} key={index}>
            {childrenRoutes(route?.children)}
          </Route>
        );
      return <Route key={index} path={route.path} element={route.element} />;
    });
  };

  return (
    
    
    <Routes>
      <Route element={<PrivateRoutes />}>{childrenRoutes(routes.private)}</Route>
      <Route element={<ProtectedRoute />}>{childrenRoutes(routes.protected)}</Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
    
  );
};

export default RoutesComp;
