import React from 'react';
import { SVGProps } from '../../helpers/types';

const AlertSuccess: React.FC<SVGProps> = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3333 7.00001C12.3333 8.4145 11.7714 9.77105 10.7712 10.7712C9.77104 11.7714 8.41449 12.3333 7 12.3333C5.58551 12.3333 4.22896 11.7714 3.22876 10.7712C2.22857 9.77105 1.66667 8.4145 1.66667 7.00001C1.66667 5.58552 2.22857 4.22897 3.22876 3.22877C4.22896 2.22858 5.58551 1.66668 7 1.66668C7.50667 1.66668 8 1.74001 8.46667 1.87334L9.51333 0.826677C8.74 0.506677 7.89333 0.333344 7 0.333344C6.12452 0.333344 5.25761 0.505782 4.44878 0.840813C3.63994 1.17584 2.90501 1.66691 2.28595 2.28596C1.03571 3.53621 0.333333 5.2319 0.333333 7.00001C0.333333 8.76812 1.03571 10.4638 2.28595 11.7141C2.90501 12.3331 3.63994 12.8242 4.44878 13.1592C5.25761 13.4942 6.12452 13.6667 7 13.6667C8.76811 13.6667 10.4638 12.9643 11.714 11.7141C12.9643 10.4638 13.6667 8.76812 13.6667 7.00001M4.27333 5.72001L3.33333 6.66668L6.33333 9.66668L13 3.00001L12.06 2.05334L6.33333 7.78001L4.27333 5.72001Z"
        fill="#12B76A"
      />
    </svg>
  );
};

export default AlertSuccess;
