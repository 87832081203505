import { SERVER_CONFIG } from '../helpers/constant';
import httpClient from './index.service';

export async function getOtpConfirmation(requestBody: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.users}/${SERVER_CONFIG.otpSend}`, requestBody);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getOtpVerification(requestBody: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.users}/${SERVER_CONFIG.otpVerify}`, requestBody);
    return response;
  } catch (error: any) {
    return error;
  }
}


export async function sendUserSignupDetails(requestBody: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.users}/${SERVER_CONFIG.signupDetails}`, requestBody);
    return response;
  } catch (error: any) {
    return error;
  }
}