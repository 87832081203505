import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import India from '../../../assets/icons/India';
import Images from '../../../assets/images';
import TextField from '../../../components/common/text-field';

const RegisterContainer = () => {
  const { control } = useFormContext();

  return (
    <Controller
      name="mobile"
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          value={field.value}
          required
          type="tel"
          inputMode="tel"
          enterKeyHint="next"
          autoComplete="tel"
          placeholder="Enter phone number"
          startIcon={
            <React.Fragment>
              <India />
              <p>+91</p>
            </React.Fragment>
          }
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          endIcon={fieldState.invalid && <Images.alertError />}
        />
      )}
    />
  );
};

export default RegisterContainer;
