import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AdaptiveTypography, Button, Modal, Typography } from '../../components/base';
import BoxContainer from '../../components/common/box-container';
import { MixPanelEvents } from '../../helpers/constant';
import { AppContext } from '../../helpers/hooks/AppContext';
import notify from '../../helpers/toastify-helper';
import { IPersonalDetailsForm } from '../../helpers/types/personalDetails.types';
import { PersonalDetailsSchema } from '../../helpers/yup/personalDetail.schema';
import { CLIENT_ROUTES } from '../../router/routes';
import { addNewUser, sendEmailVerification, verifyEmailOTP } from '../../services/users.service';
import css from './index.module.scss';
import PersonalDetailsContainer from './personal-detail-container';
import { getUserDetails } from '../../services/selfcheckin.service';
import { Box } from '@mui/material';
import { prepareForSlot } from '@mui/base/utils';
import style from '../callSchedule/scheduleCall.module.scss';
import { TextField } from '../../components/common';
import OtpInput from '../login/login-container';
import { ILoginStates } from '../../helpers/types/login.types';


const BoxSlot = prepareForSlot(Box);


const PersonalDetails: React.FC = () => {
  const { appDispatch, navigate, mixpanel } = useContext(AppContext);
  const mobileNumber = localStorage.getItem('mobile');
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState<string | null>(null)
  const [email, setEmail] = useState<string | null>(null)

  const personalDetailsForm = useForm<IPersonalDetailsForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(PersonalDetailsSchema),
    defaultValues: {
      name: '',
      email: '',
    },
  });
  const { reset, handleSubmit } = personalDetailsForm;
  const [isOTPConfirmOpen, setIsOTPConfirmOpen] = useState(false)

  const [otpStates, setOTPStates] = useState<ILoginStates>({
    value: '',
    loading: false,
    isTimer: true,
    error: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const verifyEmail = async () => {
    try {
      setIsLoading(true)
      const response: any = await verifyEmailOTP(otpStates.value);
      if (~~(response.statusCode / 100) == 2) {
        localStorage.setItem('isEmailVerified', 'true')
        navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.dashboard}`);
      } else {
        setOTPStates((prevState) => ({ ...prevState, error: true }));
      }
    } catch (error) {
      setOTPStates((prevState) => ({ ...prevState, error: true }));
      //ignore
    } finally {
      setIsLoading(false)
    }
  }


  useEffect(
    () => {
      const prefillUserDetails = async () => {
        try {
          const details = await getUserDetails();

          if (details.name) {
            personalDetailsForm.setValue('name', details.name)
            setName(details.name)
          }
          if (details.email) {
            personalDetailsForm.setValue('email', details.email)
            setEmail(details.email)
          }
        } catch (error) {
          //ignore
        }
      }
      prefillUserDetails()
    }
    , []
  )

  const handleDetailsSubmit: SubmitHandler<IPersonalDetailsForm> = async (data) => {
    localStorage.setItem('email', data.email);
    localStorage.setItem('name', data.name);
    setLoading(true);
    const formData = { ...data, mobile: mobileNumber, version: 'v2' };
    try {
      if (!email) {
        const response: any = await addNewUser(formData);
        if (response.statusCode === 201) {
          const { data } = response;
          localStorage.setItem('accessToken', data?.access_token);
          localStorage.setItem('userId', data?.id);
          appDispatch({
            type: 'setUserData',
            payload: {
              ...data,
            },
          });
          reset();

          try {
            (window as any).fbq('trackCustom', 'Sign_up_event')
          } catch (error) {
            console.log('Cannot send custom pixel')
          }
          mixpanel.track(MixPanelEvents.signUp, {
            'Sign-up Process': 'Success',
            isWebFlow: true,
          });
          // navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.email_sent}`);
        } else {
          notify({ message: response?.message, severity: 'error', dismissible: true });
          return;
        }
      }

      let response: any = await sendEmailVerification();
      if (~~(response.statusCode / 100) == 2) {
        setIsOTPConfirmOpen(true)
      } else {
        notify({ message: 'Cannot send email verification', severity: 'error', dismissible: true });
      }

    } catch (error: any) {
      if (error?.message) {
        notify({ message: error?.message, severity: 'error', dismissible: true });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={isOTPConfirmOpen}
        slots={{
          backdrop: BoxSlot,
        }}
        slotProps={{
          backdrop: {
            className: 'mitsu-select-modal-backdrop',
          },
        }}
      >
        <div className={`mitsu-select-modal-container ${style.cancelModalContainer}`} style={{ maxWidth: '800px' }}>
          <Typography variant="h3" className="mitsu-select-modal-title">
            Email Verification
          </Typography>
          <div style={{ height: '32px' }} />
          <AdaptiveTypography variant="body" mobileVariant="subheading2">
            Please enter the OTP sent to your email {personalDetailsForm.getValues('email')}.
          </AdaptiveTypography>
          <div style={{ height: '32px' }} />
          <OtpInput
            onChange={(value) => setOTPStates((prevState) => ({ ...prevState, value: value, error: false }))}
            value={otpStates.value}
            disabled={loading}
            centered
            valueLength={4}
            error={otpStates.error}
          />
          <div style={{ height: '16px' }} />
          {
            otpStates.error &&
            <AdaptiveTypography variant="label" mobileVariant="label">
              OTP entered is invalid
            </AdaptiveTypography>
          }
          <div style={{ height: '32px' }} />
          <div className={style.buttonRow}>
            <Button
              onClick={verifyEmail}
              disabled={otpStates.value.length != 4}
              loading={loading}
            // variant={'outlined-nopad'}
            // className={style.dialogSecondaryButton}
            // style={{ height: 'unset', width: 'unset' }}
            >
              Confirm
              {/* <div className={style.dialogButton}>Confirm</div> */}
            </Button>
          </div>
        </div>
      </Modal>
      <form noValidate onSubmit={handleSubmit(handleDetailsSubmit)}>
        <BoxContainer
          title={<Typography variant="h1">We&apos;re glad you&apos;re here</Typography>}
          subheader={
            <React.Fragment>
              <Typography variant="subheading1">
                Let&apos;s set up your account so you can get started on your journey to better mental health.
              </Typography>
              <section className={css.formField}>
                <FormProvider {...personalDetailsForm}>
                  <PersonalDetailsContainer name={name} email={email} />
                </FormProvider>
              </section>
            </React.Fragment>
          }
        >
          <section className={css.btnContainer}>
            <Button loading={loading} type="submit">
              Confirm
            </Button>
          </section>
        </BoxContainer>
      </form>
    </React.Fragment>
  );
};

export default PersonalDetails;