export interface IState {
  registerData: object;
  userData: object;
}

export type ActionType = 'setRegisterData' | 'setUserData' | 'setStepsData';

export interface IAction {
  type: ActionType;
  payload?: any;
}

export const appReducer = (state: IState, action: IAction) => {
  const { payload } = action;

  switch (action.type) {
    case 'setRegisterData': {
      return {
        ...state,
        registerData: {
          phone_number: payload.phone_number,
        },
      };
    }

    case 'setUserData': {
      console.log(payload)
      return {
        ...state,
        userData: payload.userData,
      };
    }

    case 'setStepsData': {
      return {
        ...state,
        stepsData: payload.stepsData,
      };
    }

    default: {
      return state;
    }
  }
};
