import { DOMAttributes, ReactNode, memo } from 'react';
import { typographyVariantType } from '../../../helpers/types';
import { useMediaQuery, useTheme } from '@mui/material';
import Typography from '../typography';

interface AdaptiveTypographyProps extends DOMAttributes<HTMLHeadingElement> {
  variant?: typographyVariantType;
  mobileVariant?: typographyVariantType;
  children: ReactNode;
  className?: string;
}

function AdaptiveTypography(props: AdaptiveTypographyProps) {
  const { variant, mobileVariant, ...otherProps } = props;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  let applicableVariant = variant;
  if (!isDesktop) {
    applicableVariant = mobileVariant ?? variant;
  }

  return <Typography variant={applicableVariant} {...otherProps} />;
}

export default memo(AdaptiveTypography);
