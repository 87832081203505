import React, { useContext, useEffect } from 'react';
import Button from '../../components/base/button';
import { AppContext } from '../../helpers/hooks/AppContext';
import notify from '../../helpers/toastify-helper';
import { CLIENT_ROUTES } from '../../router/routes';
import { emailVerification, sendEmailVerification } from '../../services/users.service';
import css from './index.module.scss';
import { AdaptiveTypography } from '../../components/base';

const EmailVerification: React.FC<{ isForced: boolean }> = ({ isForced }) => {
  const { navigate } = useContext(AppContext);
  const email = localStorage.getItem('email');

  useEffect(() => {
    sendEmailVerification();
  }, []);

  const handleEmailVerification = async () => {
    if (isForced) {
      const name = localStorage.getItem('name');
      const userId = localStorage.getItem('userId');
      const data = { name: name, email: email, id: userId };
      const response: any = await emailVerification(data);
      if (response.statusCode === 201 && response.data) {
        localStorage.setItem('emailVerified', 'true');
        navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.setup_free_call}/${CLIENT_ROUTES.scheduled_call}`);
      } else {
        notify({ message: response.message, severity: 'error', dismissible: true });
      }
    } else {
      navigate(`/${CLIENT_ROUTES.onboarding}/${CLIENT_ROUTES.dashboard}`);
    }
  };

  return (
    <section className={css.container}>
      <div className={css.headerImage}>
        <div className={css.imageDiv} />
      </div>
      <div className={css.spacer} />
      <div style={{ height: '20px' }} />

      <div className={css.pageCenter}>
        <div className={css.mainPage}>
          <div className={`${css.pageHeadContainer}`}>
            <AdaptiveTypography variant="h2" mobileVariant="h3">
              Email verification{isForced ? ' Pending' : ''}
            </AdaptiveTypography>
            <br />
            {isForced ? (
              <AdaptiveTypography variant="subheading2" className={css.textColorGrey400}>
                Please check your <span className={css.text}>{email ?? ''}</span> inbox for the link to confirm your email
                address. If you have received multiple emails, please open the <strong>most recent</strong> one to verify your email. Once
                you verify it, you will be able to move ahead with Mitsu!
              </AdaptiveTypography>
            ) : (
              <AdaptiveTypography variant="subheading2" className={css.textColorGrey400}>
                Please check your <span className={css.text}>{email ?? ''}</span> inbox for the link to confirm your email
                address.
              </AdaptiveTypography>
            )}
          </div>
        </div>
      </div>
      <div className={css.spacer} />
      <div style={{ height: '40px' }} />
      <div className={css.spacer} />

      <div className={css.buttonRow}>
        <Button onClick={handleEmailVerification} className={css.singleMainButton}>
          Continue
        </Button>
      </div>
    </section>
  );
};

export default EmailVerification;
